<template>
  <div>
    <div v-if="!showUploadsImages">
      <!-- as a default max upload 6 files -->
      <cp-post-dropzone
        @files-post="selectedFilesPost"
        :reset-files="resetRefFiles"
        @reset-value="resetValue"
        @msg-error="errorModal"
        :max="8"
      />
    </div>
    <div v-else-if="addUploads.length === 0" class="loader-container">
      <div class="loader-container__progress">
        <div class="loader-container__progress--text">
          Getting your Uploaded Files
        </div>
        <v-progress-linear
          color="white"
          height="6"
          indeterminate
          rounded
        ></v-progress-linear>
      </div>
    </div>
    <div
      v-else
      class="image-horizontal__content wrapper-scroll scroll-base container_image_scroll"
      v-on:scroll="handleScroll"
      ref="container"
    >
      <cp-progress-linear
        :items="filesProgress"
        linear-color="#C5D0E4"
        circular-color="#FFFFFF"
        color-message="#FFFFFF"
      />
      <div class="horizontal-masonry horizontal-masonry--h">
        <figure
          v-for="(item, i) in addUploads"
          :key="i"
          class="horizontal-masonry-brick horizontal-masonry-brick--h image-uploads"
        >
          <img
            class="horizontal-masonry-img"
            :src="urlThumbnail(item.thumbnails).location"
            alt="item.name"
            @dragstart="startDrag($event, item)"
            @dragend="endDrag"
            @pointerdown="
              captureImage($event, urlThumbnail(item.thumbnails).location)
            "
            @click="startClick(item)"
          />
          <v-btn
            text
            icon
            color="white"
            class="upload__btn"
            @click="deleteItem(item)"
          >
            <v-icon small>fas fa-times</v-icon>
          </v-btn>
        </figure>
      </div>
    </div>

    <isc-modal
      v-model="deleteItemModal"
      title="Are you sure you want to permanently<br> delete this item from your files?"
      subtitle="You will no be able to undo this action"
      action="1"
      action-ok="Delete"
      :loading="loadingBtn"
      @clicked="isDeleted"
    />
    <isc-modal-alert
      v-model="showAlert"
      :title="title"
      :paragraph="paragraph"
      :icon="icon"
      :iconColor="iconColor"
      :showBtn="showBtn"
      :showBtnClose="showBtnClose"
      :showMainIcon="showMainIcon"
      @clicked="resultModalAlerts"
    />
  </div>
</template>
<script>
import CpPostDropzone from "@/components/dropzone/CpPostDropzone";
import { v4 as uuidv4 } from "uuid";
import {
  mapActions,
  mapGetters,
  generateKeywords,
  CpProgressLinear,
  IscModal,
  HandleRespMixin,
  IscModalAlert,
  SignedUrlUploadMixin,
} from "@design/styleguide";
import "@/plugins/mixin";

export default {
  name: "PostUploads",
  components: {
    CpPostDropzone,
    CpProgressLinear,
    IscModal,
    IscModalAlert,
  },
  //* IMPORTANT: various methods of modal errors come from here (HandleRespMixin), also clientId variable
  mixins: [HandleRespMixin, SignedUrlUploadMixin],
  data() {
    return {
      loadingBtn: false,
      flag: false,
      file: null,
      progress: 0,
      image: null,
      imageWidth: "",
      imageHeight: "",
      showUploadsImages: false,
      deleteItemModal: false,
      selectedItem: null,
      resetRefFiles: false,
      take: 15,
      page: 1,
      firstAmountOfImages: 0,
      addUploads: [],
      //* uncomment this line to see the progress bar locally
      /* filesProgress: [
        { 
          progress: 100,
          index: 0,
          error:true,
          msgError: 'Denied Access'
        }
      ], */
    };
  },
  props: {
    files: {
      type: [Array, File],
      default: () => {
        return [];
      },
    },
  },
  computed: {
    ...mapGetters({
      uploadsImages: "post/uploads/uploads",
      elements: "post/canvas/read",
      view: "post/zoom/post",
      progressImage: "post/uploads/progress",
      filesProgress: "post/uploads/files",
    }),
  },
  watch: {
    files(files) {
      const isClient = this.validationClientId(this.clientId);
      if (!isClient) return;
      this.selectedFiles(files);
    },
    uploadsImages(items) {
      if (items.length > 0) {
        this.showUploadsImages = true;
        items.forEach((el) => {
          this.addUploads.push(el);
        });
      }
    },
  },
  mounted() {
    this.read({
      take: this.take,
      page: this.page,
      clientId: this.clientId,
      type: "image",
    });
    if (this.uploadsImages.length > 0) this.showUploadsImages = true;
  },
  methods: {
    ...mapActions({
      create: "post/uploads/CREATE",
      read: "post/uploads/FIND",
      set: "post/canvas/SET",
      setFiles: "post/uploads/FILES_PROGRESS",
      resetFiles: "post/uploads/RESET_FILES",
      resetUploads: "post/uploads/RESET_UPLOADS",
    }),
    resetValue() {
      this.resetRefFiles = false;
    },
    //* Click and Drag
    startDrag(evt, image) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setDragImage(this.image, 75, 75);
      const { width, height } = this.setHW(image);
      this.$store.commit("post/drag/DRAG_IMAGE_START", {
        ...image,
        typeItem: "image",
        uuid: uuidv4(),
        width,
        height,
        cropped: {
          width,
          height,
          x: 0,
          y: 0,
        },
      });
      this.image = null;
    },
    endDrag() {
      this.$store.commit("post/drag/DRAG_IMAGE_END");
    },
    startClick(image) {
      const { width, height } = this.setHW(image);
      this.$store.dispatch("post/drag/ADD_IMAGE_IN_CANVAS", {
        ...image,
        uuid: uuidv4(),
        x: this.view.width / 2 - width / 2,
        y: this.view.height / 2 - height / 2,
        width,
        height,
        rotate: 0,
        cropped: {
          width,
          height,
          x: 0,
          y: 0,
        },
      });
    },
    captureImage($evt, url) {
      let img = new Image();
      img.src = url;
      this.image = img;
    },
    urlThumbnail(images) {
      return images.find((item) => item.quality === "SCREEN_4");
    },
    setHW(image) {
      const imageResize = image.thumbnails.find(
        (item) => item.quality === "SCREEN_3"
      );
      let width;
      let height;
      if (imageResize.width >= 533 && imageResize.height >= 800) {
        width = 420.267;
        height = 630.4;
      } else if (imageResize.width >= 640 && imageResize.height >= 800) {
        width = 504;
        height = 630;
      } else if (imageResize.width >= 800 && imageResize.height >= 532) {
        width = 782;
        height = 522;
      } else {
        width = imageResize.width;
        height = imageResize.height;
      }
      return { width, height };
    },
    //*Upload Image functions
    selectedFiles(files) {
      this.showUploadsImages = true;
      this.setFiles({ files });
      for (const [key, file] of files.entries()) {
        this.saveAndUploadFile(file, key);
      }
    },
    selectedFilesPost(items) {
      const isClient = this.validationClientId(this.clientId);
      if (!isClient) return;
      this.showUploadsImages = true;
      let files = [];
      for (let i = 0; i < items.length; i++) {
        files.push(items[i]);
      }
      this.selectedFiles(files);
    },
    //* Upload image to serverless and save to bd.
    async saveAndUploadFile(file, index) {
      try {
        if (!file || file.type.indexOf("image/") !== 0) return;
        const fileSizeKb = file.size * 0.001;
        const originalName = file.name;
        //* signed Url S3 and upload too. (Mixin File)
        const { fileName, contentType } = await this.setUrlSignedS3AndUpload(
          file,
          index,
          this.clientId,
          fileSizeKb
        );
        // get Object from S3 and process for all thumbnails. (Mixin File)
        const respProcessThumbnails = await this.getProcessThumbnailsImage({
          fileKey: fileName,
          contentType,
          index,
        });
        //const resp = await this.uploadFile(file, index, clientId, fileSizeKb);
        //* Generate keywords
        const keywords = generateKeywords(originalName);

        this.resetFiles({ index });

        const {
          data: {
            file: { full, thumbnails },
          },
        } = respProcessThumbnails;
        const upload = {
          path: full.path,
          mimeType: file.type,
          width: full.width,
          height: full.height,
          thumbnails,
          sizeKb: fileSizeKb,
        };

        const object = {
          clientId: this.clientId,
          typeUser: "user",
          keywords,
        };
        await this.create({ upload, object });
        //*Reset all for uploads.
        this.resetTotalUploads();
      } catch (error) {
        console.log("[Error] in function saveAndUploadFile", error);
        this.resetRefFiles = true;
        // this is for btnInputFiles component
        this.$store.commit("global/util/CHANGE_ACTIVE_REFERENCE_INPUT", true);
        setTimeout(() => {
          this.resetFiles({ index });
        }, 4000);
      }
    },
    /* async uploadFile(file, index, clientId, fileSizeKb) {
			let formData = new FormData();
			//formData.append('type', type);
			formData.append('file', file);
			const { data } = await this.$store.dispatch('post/uploads/UPLOAD_FILE', {
				formData,
				index,
				clientId, 
				fileSizeKb
			});
			return data;
		}, */
    resetTotalUploads() {
      this.resetUploads();
      this.addUploads = [];
      this.firstAmountOfImages = 0;
      this.page = 1;
      this.take = 15;
      this.read({
        take: this.take,
        page: this.page,
        clientId: this.clientId,
        type: "image",
      });
    },
    validationClientId(id) {
      if (!id) {
        this.errorModal("Client does not exist");
        return false;
      } else return true;
    },
    deleteItem(item) {
      this.selectedItem = item;
      this.deleteItemModal = true;
    },
    async isDeleted(e) {
      if (!e) return;
      this.loadingBtn = true;
      await this.$store.dispatch("post/uploads/DELETE", {
        id: this.selectedItem.id,
        responseFunc: this.responseMessageGraphql,
      });
      this.deleteItemModal = false;
      this.resetTotalUploads();
      this.loadingBtn = false;
    },
    //*==== END UPLOAD FILE =========
    //* Dynamic Scroll
    handleScroll() {
      /* 
				scrollHeight: all height scroll
				scrollTop: height location where scroll is marked. 
				style.height: height of view where scroll works.
			*/
      const { scrollHeight, scrollTop, clientHeight } = this.$refs.container;
      const entireHeight = Math.round(scrollTop + clientHeight);

      if (entireHeight >= scrollHeight) {
        const validationLengthImages = this.validationMoreImages();
        if (!validationLengthImages) return;
        //console.log('More item ');
        this.addItem();
      }
    },
    addItem() {
      this.page++;
      this.read({
        take: this.take,
        page: this.page,
        clientId: this.clientId,
        type: "image",
      });
    },
    validationMoreImages() {
      if (this.firstAmountOfImages === 0) {
        this.firstAmountOfImages = this.addUploads.length;
        return true;
      }
      if (this.firstAmountOfImages < this.addUploads.length) {
        this.firstAmountOfImages = this.addUploads.length;
        return true;
      } else return false;
    },
  },
};
</script>

<style lang="scss" scoped>
//* this two scss we are using to other projects.
//* Why did not those classes write to Scss global?
//* Because absolute position (Button) puts aside the image
.upload__btn {
  position: absolute;
  right: 5px;
  top: 5px;
  display: none;
  color: #fff;
}
.horizontal-masonry-brick:hover .upload__btn {
  display: block;
}
//* ==== END BUTTON ====
</style>
