import "./set-public-path";
import Vue from "vue";
import singleSpaVue from "single-spa-vue";
import "./assets/scss/global.scss";
import App from "./App.vue";
import router from "./router";

import { vuetify, store, } from "@design/styleguide";

Vue.config.productionTip = false;
//Vue.use(VueHtml2Canvas);

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render: (h) => h(App),
    router,
    vuetify,
    store
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
