<template>
    <v-menu 
      offset-y 
      origin="center" 
      nudge-top="-5"
      nudge-left="130"
      :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
          text
          v-bind="attrs" 
          v-on="on"
          small> Filter </v-btn>
      </template>
      <v-list outlined>
        <v-list-item 
          v-for="(item, index) in items" 
          :key="index"
          :class="selectedDetail.currentValues?.filter?.type === item.type ? 'active' : ''"
        >
          <v-list-item-content @click="changeFilter(item)">
            <img
              class="v-list__item-image"
              :src="item.image"
              width="100%"
              :style="{ filter: `${item.type}(${item.value}${item.format})` }"
            />
            <p class="v-list-item__paragraph">{{ item.title }}</p>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
export default {
  name: "FilterCanvas",
  data: () => ({
    filterActived: true,
  }),
  computed: {
    selected() {
      return this.$store.getters["post/image/selected"];
    },
    images() {
      return this.$store.getters['post/drag/images'];
    },
    selectedDetail() {
      return this.$store.getters["post/image/selectedDetail"]
    },
    items() {
      return [
        { title: "Default", type: '', value: '', format: '', image:"https://image-store.isocialcube.com/design/images/image-filter.webp"},
        { title: "Sepia", type: "sepia", value: 1, format: '', image: "https://image-store.isocialcube.com/design/images/image-filter.webp" },
        { title: "Greyscale", type: "grayscale", value: 1, format: '', image: "https://image-store.isocialcube.com/design/images/image-filter.webp"},
        { title: "Negative", type: "invert", value: 1, format: '', image: "https://image-store.isocialcube.com/design/images/image-filter.webp"},
        { title: "Saturation", type: "saturate", value: 2, format: '', image: "https://image-store.isocialcube.com/design/images/image-filter.webp"},
        { title: "Blur", type: "blur", value: 3, format: 'px', image: "https://image-store.isocialcube.com/design/images/image-filter.webp"},
      ]
    }
  },
  methods: {
    changeFilter(item) {
      this.$store.dispatch("post/navControls/CHANGE_FILTER", {
        uuid: this.selected,
        filter: item,
      });

      this.$store.dispatch('post/image/UPDATE_IMAGE_SELECTED', this.selected)
      // this.$store.dispatch('post/SAVE_CHANGES');
      this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content {
  box-shadow: none;
  padding-top: 22px;
}
.v-list {
  border-radius: 6px;
  padding: 20px 15px;
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    height: 22px;
    width: 28px;
    background: #fff;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    left: 50%;
    transform: translateX(-50%);
    top: -22px;
  }
}
.v-list-item {
  width: 50%;
  flex: auto;
  padding: 0 5px;
  padding-bottom: 8px;
  cursor: pointer;

  &.active &__paragraph {
    color: #00a5ff;
  }
}

.v-list-item__content {
  padding: 0;
}
.v-list-item__paragraph {
  margin: 0;
  color: #5d6b88;
  font-family: Oxygen;
  margin-top: 3px;
}
::v-deep.v-menu__content {
  border: none !important;
  box-shadow: 0px 0px 0px 0px rgb(44 56 82 / 10%) !important;
}
</style>