<template>
  <v-menu
    offset-y
    origin="center"
    nudge-top="-5"
    nudge-left="70"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on" small> Flip </v-btn>
    </template>
    <v-list outlined>
      <v-list-item v-for="(item, index) in items" :key="index">
        <v-list-item-content @click="changeFlip(item)">
          <p class="v-list-item__paragraph">{{ item.title }}</p>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "FlipCanvas",
  data: () => ({
    items: [
      { title: "Flip Horizontally", type: "horizontal", value: 1 },
      { title: "Flip Vertically", type: "vertical", value: 1 },
    ],
  }),
  computed: {
    selected() {
      return this.$store.getters["post/image/selected"];
    },
    images() {
      return this.$store.getters["post/drag/images"];
    },
  },
  methods: {
    changeFlip(item) {
      item.value *= -1;

      this.$store.dispatch("post/navControls/CHANGE_FLIP", {
        uuid: this.selected,
        flip: this.items,
      });
      // this.$store.dispatch('post/SAVE_CHANGES');
      this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content {
  box-shadow: none;
  padding-top: 22px;
}
.v-list {
  border-radius: 6px;
  padding: 5px;
  width: 180px;
  position: relative;
  border: none;
  &::before {
    content: "";
    position: absolute;
    height: 22px;
    width: 28px;
    background: #fff;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    left: 50%;
    transform: translateX(-50%);
    top: -20px;
  }
}

.v-list-item {
  cursor: pointer;
  min-height: 35px;
}

.v-list-item__content {
  padding: 0;
}
.v-list-item__paragraph {
  margin: 0;
  color: #5d6b88;
  font-family: Oxygen;
  margin-top: 3px;
}
::v-deep.v-menu__content {
  border: none !important;
  box-shadow: 0px 0px 0px 0px rgb(44 56 82 / 10%) !important;
}
</style>