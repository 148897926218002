<template>
  <div>
    <v-list dense class="px-0">
			<v-list-item-group
				mandatory
				class="post-bar"
			>
				<sidebar-sub-menu 
					v-for="item in menus" 
					:item="item" 
					:key="item.name"
				/>
			</v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import  SidebarSubMenu from "../sidebarSubMenu/SidebarSubMenu.vue";
export default {
  name: "SidebarMenu",
  components: {
    SidebarSubMenu
  },
  props: {
    menus: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

};
</script>

<style lang="scss">
.post{
	&-bar{
		.v-list-item--active{
			background-color: #222D3A;
		}
		.v-list-item--active .v-list-item__content .v-list-item__title{
			color: #00A5FF !important;
		}
		.v-list-item--active .v-list-item__icon .v-icon svg path{
			color:#00A5FF;
			fill: currentColor;
		}
		.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover {
			background: none;
		}

		.v-list-item--active:hover{
			background: none;
		}
	}
}


</style>
