<template>
  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open }" @click="open = !open">
      {{
        this.default
          ? this.default
          : this.options.length > 0
          ? this.options[0].text
          : null
      }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        :style="{ color: option.color, fontFamily: option.font }"
        @click="
          selected = option.text;
          open = false;
          $emit('input', option);
        "
      >
        {{ option.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomSelect',
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0].text
        : null,
      open: false,
    };
  },
  computed: {
    model: {
      get() {
        return this.default;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {
    // this.$emit("input", this.selected);
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  position: relative;
  width: 187px;
  text-align: left;
  outline: none;
  height: 40px;
  line-height: 47px;
  
  .selected {
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid #c5d0e4;
    color: #000000de;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 14px;
    box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62);
    border-radius: 3px;

    &:after {
      position: absolute;
      content: "";
      top: 20px;
      right: 1em;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-color: rgba(93, 107, 136, 1) transparent transparent transparent;
    }
  }

  .items {
    color: #fff;
    border-radius: 0px 0px 6px 6px;
    overflow: auto;
    border-right: 1px solid #c5d0e4;
    border-left: 1px solid #c5d0e4;
    border-bottom: 1px solid #c5d0e4;
    position: absolute;
    background-color: #ffffff;
    left: 0;
    right: 0;
    z-index: 1;
    font-size: 14px;
    max-height: 300px;
  }
}





.custom-select .items div {
  color: rgb(0, 0, 0);
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #d7f1ff;
}

.selectHide {
  display: none;
}
</style>
