<template>
  <div class="section">
    <div class="color-area">
      <span>Solid color</span>
      <div class="colors-wrap">
        <div
          class="colors_palette custom-palette"
          :class="{ active: colorSolidPickerActived }"
          @click="openSolidPicker"
        >
          <i class="fas fa-palette"></i>
        </div>
        <div
          class="colors_palette static-palette"
          v-for="color in colors"
          :key="color.id"
          :class="
            color.id === colorActived.id && 'backgroundC' === colorActived.tipo
              ? 'active'
              : ''
          "
          :style="{ background: color.background }"
          @click="clickColor(color)"
        >
          {{ color.active }}
        </div>
      </div>
      <isc-color-picker
        v-if="colorSolidPickerActived"
        v-model="colorSolidPicker"
        class="solid-color-picker"
        @click.native="colorSolidPickerClicked"
      >
      </isc-color-picker>
    </div>
    <div class="color-area mt-5">
      <span>Gradient color</span>
      <div class="colors-wrap">
        <div
          class="colors_palette custom-palette"
          :class="{ active: colorGradientPickerActived }"
          @click="
            colorGradientPickerActived = !colorGradientPickerActived;
            colorSolidPickerActived = false;
          "
        >
          <i class="fas fa-palette"></i>
        </div>
        <div
          class="colors_palette static-palette"
          v-for="gcolor in gcolors"
          :key="gcolor.id"
          :style="{ background: gcolor.background }"
          :class="{
            active:
              colorActived.id == gcolor.id &&
              colorActived.tipo == 'backgroundL',
          }"
          @click="clickColorLinear(gcolor)"
        ></div>
      </div>
      <isc-gradient-picker
        v-show="colorGradientPickerActived"
        v-model="colorGradientPicker"
        :gradientColors="gradientColorsSel"
        @click.native="colorGradientPickerClicked"
        class="gradient-color-picker"
      ></isc-gradient-picker>
    </div>
  </div>
</template>

<script>
import { mapActions, LinearGradient } from "@design/styleguide";
import { IscColorPicker } from "@design/styleguide";
import IscGradientPicker from "@/components/inputs/IscGradientPicker";
export default {
  components: {
    IscColorPicker,
    IscGradientPicker,
  },
  data() {
    return {
      selectKnob: 0,
      gradient: new LinearGradient({
        angle: 0,
        stops: [
          ["#FFFFFF", 0],
          ["#FFFFFF", 1],
        ],
      }),
      colorSolidPicker: "",
      colorGradientPicker: "",
      colorGradientPickerActived: false,
      colorSolidPickerActived: false,
      colors: [
        { id: 1, background: "#F55858" },
        { id: 2, background: "#FFAA5C" },
        { id: 3, background: "#F9ED83" },
        { id: 4, background: "#B4F276" },
        { id: 5, background: "#75CEFF" },
        { id: 6, background: "#6371F2" },
        { id: 7, background: "#000000" },
      ],
      gcolors: [
        {
          id: 1,
          tipo: "backgroundL",
          background: `#F55858 linear-gradient(#FFBF00 0%, rgba(255, 255, 255, 0) 100%)`,
        },
        {
          id: 2,
          tipo: "backgroundL",
          background:
            "#FFAA5C linear-gradient(#FC2BDA 0%, rgba(255, 255, 255, 0) 100%)",
        },
        {
          id: 3,
          tipo: "backgroundL",
          background:
            "#F9ED83 linear-gradient(#44BDFF 0%, rgba(255, 255, 255, 0) 100%)",
        },
        {
          id: 4,
          tipo: "backgroundL",
          background: "linear-gradient(180deg, #B4F276 0%, #00A5FF 100%)",
        },
        {
          id: 5,
          tipo: "backgroundL",
          background:
            "#75CEFF linear-gradient(#5767FB 0%, rgba(255, 255, 255, 0) 100%)",
        },
        {
          id: 6,
          tipo: "backgroundL",
          background: "linear-gradient(180deg, #FF89BB 0%, #5E2DEB 100%)",
        },
        {
          id: 7,
          tipo: "backgroundL",
          background:
            "linear-gradient(180deg, #FFFFFF 0%, #757575 31.7%, #000000 85.97%)",
        },
      ],
      types: ["hex", "hexa", "rgba", "hsla", "hsva"],
      type: "hex",
      hex: "#FFFFFF",
      rgba: { r: 255, g: 0, b: 255, a: 1 },
      hsla: { h: 300, s: 1, l: 0.5, a: 1 },
      hsva: { h: 300, s: 1, v: 1, a: 1 },
      colorActived: {
        id: null,
        tipo: null,
      },
      gradientColorsSel: [],
    };
  },
  computed: {
    color: {
      get() {
        return this.$store.getters["post/background/bg"].background;
      },
      set(v) {
        this.$store.commit("post/background/SET_BGCOLOR", v);
      },
    },
    showColor() {
      let newColor = {};
      newColor.tipo = "backgroundC";
      newColor.background = this.color;
      return "";
    },
    selectedBackground() {
      return this.$store.getters["post/background/background"];
    },
    selectedType() {
      return this.$store.getters["post/background/type"];
    },
  },
  methods: {
    ...mapActions({
      set: "post/canvas/SET",
    }),
    endDrag: function (background) {
      background.tipo = "backgroundC";
    },
    clickColor(color) {
      this.colorActived = color;
      this.colorSolidPicker = color.background + "FF";
      color.type = "backgroundC";
      this.$store.dispatch("post/background/ADD_BACKGROUND_IN_CANVAS", color);
    },
    endDragLinear: function (background) {
      background.tipo = "backgroundL";
    },
    clickColorLinear(color) {
      console.log(color.background);
      this.colorActived = color;
      let prevCadenaColors = color.background;
      const index = prevCadenaColors.indexOf("deg,");
      if (index > -1) {
        prevCadenaColors = prevCadenaColors.slice(index + 4, -1);
      } else {
        const indexPar = prevCadenaColors.indexOf("(");
        prevCadenaColors = prevCadenaColors.slice(indexPar + 1, -1);
      }
      const prevLst = prevCadenaColors.split("%,");
      this.gradientColorsSel = prevLst.map((cadena) => {
        cadena = cadena.trim();
        if (cadena.charAt(cadena.length - 1) == "%") {
          cadena = cadena.slice(0, -1);
        }
        const lastSpaceIndex = cadena.lastIndexOf(" ");
        const color = cadena.slice(0, lastSpaceIndex);
        const percent = Number(cadena.slice(lastSpaceIndex));
        return {
          color,
          percent,
        };
      });
      color.type = "backgroundL";

      this.$store.dispatch("post/background/ADD_BACKGROUND_IN_CANVAS", color);
    },
    openSolidPicker() {
      this.colorSolidPickerActived = !this.colorSolidPickerActived;
      this.colorGradientPickerActived = false;
    },
    colorSolidPickerClicked() {
      this.colorActived = false;
      let newColor = {};
      newColor.type = "backgroundC";
      newColor.background = this.colorSolidPicker;
      this.$store.dispatch(
        "post/background/ADD_BACKGROUND_IN_CANVAS",
        newColor
      );
    },
    colorGradientPickerClicked() {
      this.colorActived = false;
      const linear = {};
      linear.type = "backgroundL";
      linear.background = this.colorGradientPicker;
      this.$store.dispatch("post/background/ADD_BACKGROUND_IN_CANVAS", linear);
    },
  },
};
</script>

<style lang="scss">
.solid-color-picker {
  position: absolute;
  left: 20px;
  top: 100px;
  z-index: 5;
}
.gradient-color-picker {
  position: absolute;
  left: 10px;
  top: 100px;
  z-index: 2;
}
.section {
  padding-bottom: 26px;
  // margin: 0px 0px 26px 15px;
}
// .color-picker {
//   position: absolute;
// }
.v-color-picker {
  border-radius: 6px;
}
.color-area {
  position: relative;
  span {
    font-size: 10px;
    line-height: 16px;
    color: #ffffff;
  }
  .colors-wrap {
    margin-top: 22px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .colors_palette {
      &.active {
        border: 1.5px solid #ffffff;
      }
      width: 30px;
      height: 30px;

      box-sizing: border-box;
      border-radius: 100px;
      text-align: center;
      color: #96a0b8;
      cursor: pointer;
      margin-right: 10px;
      &.static-palette {
        &:hover,
        &.active {
          border: 1.4px solid #ffffff;
        }
      }
      &.custom-palette {
        border: 1px solid #96a0b8;
        &:hover,
        &.active {
          border: 1px solid #ffffff;
          background: #222d3a;
          color: #fff;
        }
      }
      i {
        font-size: 12px;
      }
      //background: linear-gradient(180deg, #FFBF00 0%, rgba(255, 255, 255, 0) 100%), #F55858;
    }
  }
  .color-picker {
    background-color: white;
    width: 300px;
    border-radius: 6px;
    // padding: 25px 25px 25px 25px;
    .v-color-picker__canvas {
      border-radius: 6px;

      &-dot {
        border: 2px solid #ffffff;
        box-shadow: none;
      }
    }
    .v-color-picker__dot {
      border-radius: 3px;
      width: 35px;
      height: 22px;
    }
    .v-color-picker__edit .v-btn {
      display: none;
    }
    span {
      display: none;
    }

    // .v-color-picker__controls {
    //   position: relative;

    //     .v-color-picker__preview:not(.v-color-picker__preview--hide-alpha) .v-color-picker__hue {
    //         margin-bottom: 24px;
    //         position: absolute;
    //         top: 13px;
    //         left: 0;
    //         height: 18px;
    //         border-radius: 11px;
    //     }
    // }
  }
}
</style>
