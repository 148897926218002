<template>
  <div class="menu__selector">
    <div class="menu__selector_options">
      <div class="selectors">
        <div class="full-content">
          <v-navigation-drawer
              permanent
              app
              width="432"
              class="sidebar__content"
          >
            <v-list
                class="pa-0"
                expand
                dense
                subheader
                min-width="auto"
            >
              <sidebar-menu :menus="menus"/>
            </v-list>
          </v-navigation-drawer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarMenu from  "./components/sidebarMenu/SidebarMenu.vue";
export default {
  name: "SidebarModule",
  components: {
    SidebarMenu
  },
  data: () => ({
    menus: [
      {
        name: "Templates",
        icon: "$templates",
        active: false,
      },
      { name: "Images", icon: "$images", active: false },
      {
        name: "Background",
        icon: "$background",
        active: false,
      },
      { name: "Shapes", icon: "$shapes", active: false },
      { name: "Texts", icon: "$font", active: false },
      {
        name: "Uploads",
        icon: "$uploads",
        active: false,
      },
      { name: "Saved", icon: "$saved", active: false },
    ],
  }),
}
</script>

<style scoped lang="scss" src="./SidebarModule.scss"></style>
