<template>
  <div class="d-flex align-items-center">
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <div 
          v-bind="attrs"
          v-on="on" class="d-flex align-center me-3">
          <p class="me-3 mb-0">Fill</p>
          <div class="button-color" :style="{ background: shapeColor }"></div>
        </div>
        </template>
        <div class="white pa-3">
          <isc-color-picker @input="setShapeColor($event)" :value="shapeColor"></isc-color-picker>
        </div>
    </v-menu>

    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <div 
          v-bind="attrs"
          v-on="on" class="d-flex align-center me-3">
          <p class="me-3 mb-0">Stroke</p>
          <div class="button-color" :style="{ background: strokeColor }"></div>
        </div>
        </template>
        <div class="white pa-3">
          <isc-color-picker @input="setStrokeColor($event)" :value="strokeColor"></isc-color-picker>
        </div>
    </v-menu>

    <custom-input-number
      :default="strokeWidth"
      :from="'shape'"
      class="select"
      @input="setStrokeWidth($event)"
    ></custom-input-number>
  </div>
</template>

<script>
import { IscColorPicker } from '@design/styleguide';
import CustomInputNumber from "@/components/textTools/CustomInputNumber";
export default {
  name: "ShapeControls",
  components: {
    IscColorPicker,
    CustomInputNumber
  },
  data() {
    return {
      brightness: 1,
      contrast: 1,
      saturate: 1,
      temperature: 1,
      blur: 0,
      masterFilter: "",
      imgTrans: "",
      flipVert: false,
      flipHori: false,
      foo: 0,
    };
  },
  computed: {
    element() {
      return this.$store.getters["post/rect/element"];
    },
    selected() {
      return this.$store.getters["post/image/selected"];
    },
    shapeColor() {
      return this.$store.getters["post/image/selectedDetail"].fillDefault || '#C5D0E4';
    },
    strokeColor() {
      return this.$store.getters["post/image/selectedDetail"].stroke || '#C5D0E4';
    },
    strokeWidth() {
      return this.$store.getters["post/image/selectedDetail"].strokeWidth || 1;
    }
  },
  methods: {
    increment() {
      this.foo = parseInt(this.foo, 10) + 1;
    },
    decrement() {
      this.foo = parseInt(this.foo, 10) - 1;
    },
    setStrokeWidth(e) {
       this.$store.dispatch('post/shapeControls/CHANGE_STROKE_IN_SHAPE', {
        uuid: this.selected,
        stroke: {
          color: this.strokeColor,
          width: e
        }
      });
      this.$store.dispatch('post/image/UPDATE_IMAGE_SELECTED', this.selected);
    },
    setStrokeColor(e) { console.log(e);
      this.$store.dispatch('post/shapeControls/CHANGE_STROKE_IN_SHAPE', {
        uuid: this.selected,
        stroke: {
          color: e,
          width: this.strokeWidth
        }
      });
      this.$store.dispatch('post/image/UPDATE_IMAGE_SELECTED', this.selected);
    },
    setShapeColor(e) {
      this.$store.dispatch('post/shapeControls/CHANGE_FILL_IN_SHAPE', {
        uuid: this.selected,
        fill: e
      })
      this.$store.dispatch('post/image/UPDATE_IMAGE_SELECTED', this.selected);
    }
  },
};
</script>

<style lang="scss">
.v-color-picker {
  padding: 10px 10px 10px 10px;
  max-width: 500px !important;
  border-radius: 6px;
}
.v-toolbar__content {
  display: flex;
}
.shape-color-picker {
  display: flex;
    align-items: center;

    & .content-color {
      left: 10px;
      // top: 40px;
    }
}
.button-color {
    height: 20px;
    width: 20px;
    border-radius: 2px;
    cursor: pointer;
}
</style>