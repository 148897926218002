<template>
  <v-app>
    <v-main style="padding: 0px;">
      <router-view></router-view>
      <no-auth-modal />
      <cp-global-modal-alert />
    </v-main>
  </v-app>
</template>
<script>
import { NoAuthModal, CpGlobalModalAlert } from "@design/styleguide";

export default {
  components: {
    NoAuthModal,
    CpGlobalModalAlert
  },
}
</script>
<style scoped>
/* #post {
  margin-top: 60px;
} */
</style>
