<template>
  <v-footer class="lienzo__footer">
    <div class="footer_row">
      <div class="d-flex justify-start align-center" style="width: 200px">
        <v-icon size="13" class="mr-2" @click="zoomTransform(count - 1)"
          >fas fa-minus</v-icon
        >
        <div style="width: 100px" class="d-flex justify-center align-center">
          <select-input
            :items="items"
            v-model="zoom.transform"
            text="name"
            id="id"
            @input="zoomChange(zoom)"
          />
        </div>
        <v-icon size="13" class="ml-2" @click="zoomTransform(count + 1)"
          >fas fa-plus</v-icon
        >
      </div>
      <div class="d-flex justify-center align-center" style="width: 200px">
        <div
          class="
            d-flex
            justify-center
            align-center
            position-relative
            flex-column
          "
        >
          <div class="d-flex">
            <p class="font-14 lienzo__title font-weight-bold">
              {{ size.title }}
            </p>
            <v-menu
              nudge-top="300"
              nudge-left="210"
              z-index="10000"
              content-class="arrow arrow-down"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon width="20" height="20">
                  <v-icon size="16">$edit</v-icon>
                </v-btn>
              </template>
              <v-list width="240">
                <v-list-item
                  v-for="(post, i) in sizes"
                  :key="i"
                  @click="resize(post)"
                  :class="{'active': namePost == post.title }"
                  
                >
                  <v-list-item-icon
                    :style="{padding: `0px 0px 0px ${post.leftPad}px`}"
                  >
                    <v-img
                      :alt="post.title"
                      :src="post.image"
                      :width="post.maxWidth"
                      
                    />
                  </v-list-item-icon>
                  <v-list-item-content  :style="{padding: `0px 0px 0px ${post.leftPad}px`}">
                    <h6>{{ post.title }}</h6>
                    <p>{{ post.width }} x {{ post.height }}</p>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <p class="font-10 lienzo__size">
            {{ size.width }} x {{ size.height }}
          </p>
        </div>
      </div>
      <div class="d-flex justify-center align-center" style="width: 200px">
        <v-btn
          class="mx-3 font-weight-bold"
          color="primary"
          elevation="0"
          :disabled="!postId" 
          exact
          small
          text
					@click="redirectUrl"
        >
          Go to Publish
        </v-btn>
        <save-modal
          type="post"
          @folder-id="idFolder"
          :loading-save="postLoading"
          :idProject="postId"
          @edit-addon="updatePost()"
					:nameProject="postName"
					:hide-init-modal="false" 
					:clientId="clientId"
          @trigger-success="setSuccessMessage"
          @trigger-error="setErrorMessage"
        />
      </div>
    </div>
  </v-footer>
</template>

<script>
import { SaveModal, SelectInput } from "@design/styleguide";
export default {
  name: "FooterModule",
  components: {
    SelectInput,
    SaveModal,
  },
  computed: {
    zoom() {
      return this.$store.getters["post/zoom/zoom"];
    },
    size() {
      return this.$store.getters["post/zoom/post"];
    },
    postId() {
      return this.$store.getters["post/main/id"];
    },
    postUuid() {
      return this.$store.getters["post/main/postUuid"];
    },
  },
  props: {
    postLoading: {
      type: Boolean,
      default: false,
    },
		postName: {
			type: String,
			default: ''
		},
		clientId:{
			type: Number,
			default: 0
		}
  },
  data: () => ({
    items: [
      { id: 0.25, name: "25%" },
      { id: 0.5, name: "50%" },
      { id: 0.8, name: "80%" },
      { id: 1, name: "100%" },
      { id: 1.25, name: "125%" },
      { id: 1.5, name: "150%" },
      { id: 2, name: "200%" },
    ],
    sizes: [
      {
        image: require("@/assets/img/post.png"),
        title: "Post (Fb/Ig/LinkedIn)",
        width: 1200,
        height: 1200,
        //maxHeight: 36,
        maxWidth: 27.34,
        leftPad: 5
      },
      {
        image: require("@/assets/img/twitter.png"),
        title: "Twitter Post",
        width: 1024,
        height: 512,
        //maxHeight: 27.86,
        maxWidth: 39,
        leftPad: 0
      },
      {
        image: require("@/assets/img/youtube.png"),
        title: "Youtube Video",
        width: 1920,
        height: 1080,
        //maxHeight: 30,
        maxWidth: 38.77,
        leftPad: 0

      },
      {
        image: require("@/assets/img/addon.png"),
        title: "Addon Post",
        width: 1200,
        height: 626,
        //maxHeight: 27.86,
        maxWidth: 39,
        leftPad: 0
      },
    ],
    count: 3,
    namePost: "Post (Fb/Ig/LinkedIn)"
  }),
  created() {
    this.count = this.items.findIndex(i => i.id === this.zoom.transform);
  },
  methods: {
    redirectUrl(){
      const url = `/publish?type=post&uuid=${this.postUuid}`
      this.$router.push(url)
    },
    idFolder(val) {
      this.$emit("id-folder", val);
    },
    zoomTransform(level) {
      if (level <= -1 || level >= 7) return;

      this.count = level;

      const zoom = this.items[level];

      this.setZoom(zoom.id);
    },
    zoomChange(level) {
      this.count = this.items.findIndex((item) => item.id === level.transform);

      this.setZoom(level.transform);
    },
    setZoom(transform) {
      this.$store.commit("post/zoom/SET_ZOOM", {
        width: this.size.width * transform,
        height: this.size.height * transform,
        transform,
      });
    },
    resize(post) {
      this.namePost = post.title;
      this.$store.commit("post/zoom/SET_SIZE", {
        title: post.title,
        width: post.width,
        height: post.height,
      });
      // this.setZoom(1);
      this.zoomChange(this.zoom)
      // this.count = 3;
    },
    updatePost() {
      this.$emit("update-post", this.postId);
    },
    //** ALERTS MODAL */
    setSuccessMessage(msg) {
      this.$emit('send-success-msg', msg);
    },
    setErrorMessage(msg){
      this.$emit('send-error-msg', msg);
    }
  },
};
</script>

<style scoped lang="scss">
.footer_row {
  position: relative;
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
  box-sizing: border-box;
}
.lienzo {
  &__size {
    line-height: 10px;
    justify-content: center;
    margin: 0;
    padding: 0;
    display: flex;
    align-content: center;
    position: relative;
    left: -10px;
  }
  &__title {
    line-height: 18px;
    justify-content: center;
    margin: 0;
    padding: 0;
    display: flex;
    align-content: center;
    position: relative;
  }
}
.v-list-item {
  // 5D6B88
  // gris D2D5DE

  &__icon {
    ::v-deep .v-image__image {
      filter: grayscale(1);
      opacity: .4;
    }
  }

  &__content {
    color: #5D6B88;

    & h6 {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
    }

    & p {
      font-weight: 300;
      font-size: 10px;
      line-height: 16px;
    }

    
  }
  &.active &__content {
    color: #00A5FF;
    // & p {
    // }
  }

  &.active &__icon {
    ::v-deep .v-image__image {
      filter: grayscale(0);
      opacity: 1;
    }
  }
}
</style>
