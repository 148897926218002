<template>
  <form enctype="multipart/form-data">
    <div class="dropzone">
      <div v-if="!uploading" class="call-to-action">
				<v-img 
					:height="42"
					class="dropzone-image"
					src="https://i.ibb.co/BTVTkLp/Vector.png" 
					contain
				>
        </v-img>
				<p class="common-text dropzone-text-drag">Drag and Drop</p>
				<p class="common-text dropzone-text-or">or</p>
				<div class="dropzone-btn">
					<v-btn
							width="133"
							height="35"
							color="primary"
							small
						>
						Choose your files
					</v-btn>
				</div>
      </div>
			<input 
				ref="file" 
				type="file" 
				class="input-file" 
				@change="sendFile"
				multiple="multiple"
			/>
    </div>
  </form>
</template>
<script>
import { UploadFunctionsMixin } from "@design/styleguide";
export default {
  name: "CpPostDropzone",
  mixins: [UploadFunctionsMixin],
  data() {
    return {
      file: null,
      message: "",
      error: false,
      uploading: false,
      progress: 0, 
			uploads: [],
			statesSuccess: [],
			files: [],
			count: 0,
    };
  },
	props: {
    resetFiles: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number,
      default: () => {
        return 6
      }
    }
  },
  watch:{
    resetFiles(val){
      if(val) {
        this.$refs.file.value = null;
        this.$emit('reset-value');
      }
    },
  },
  methods: {
		sendFile() {
			this.files = this.$refs.file.files;
			if(this.files.length < 0) return;
			let isValidateMaxUploads = this.validateMaxUploadsFiles(this.max, this.files);
      if(isValidateMaxUploads) return;
      this.validationImages(this.files);	
    },
		validationImages(files){
			this.count = 0;
			this.statesSuccess = [];
			this.uploads = [];
      for (const file of files) {
        this.uploads.push(file)
				let success = true
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (evt) => {
          let img = new Image();
          img.onload = () => {
            success = img.width >= 160 && img.height >= 160;
						this.validationProcess(success);
          };
          img.src = evt.target.result;
        };
      }

		},
		validationProcess(success){
			this.statesSuccess.push(success);
			if(!success){ 
				this.count++
        let msg ='Upload images larger than 160 * 160 pixels.';
        this.$emit('msg-error', msg);
			}
			if((this.statesSuccess.length == this.files.length) && this.count == 0 ){
				this.$emit('files-post', this.uploads);
			}
		},
  }
};
</script>

<style lang="scss" scoped>
.dropzone {
  min-height: calc(96vh - 100px);
  height: calc(96vh - 100px);
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: 0.5px dashed #ffffff;
  outline-offset: -10px;

  .input-file {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;
  }
  &:hover {
    background: #273444;
  }
	&-image{
		/* background: #C5D0E4;
		opacity: 0.5; */
		margin-bottom: 11px;
	}
	&-text-drag{
		color: #C5D0E4;
		font-weight: bold; 
		text-align: center;
		margin-bottom: 13.35px
	}
	&-text-or{
		text-align: center;
	}
	&-btn{
		display: flex;
    flex-direction: row;
    justify-content: center;
	}
  .call-to-action {
    font-size: 1.2rem;
    color: #c5d0e4;
  }
}
</style>
