<template>
  <div :style="currentImageStyles">
    <div
      v-if="isCrop"
      class="corner u6DiEw _7WSEvA xk1pAg"
      :style="selectedStylesCropCut"
      ref="resizeCropContainer"
    >
      <div class="corner__container" ref="boxCrop" :style="boxCropStyles">
        <div class="corner__shadow" :style="boxShadowCropStyles">
          <img :src="selectedDetail.path" alt="" style="width: 100%; height: 100%" />
        </div>
        <div class="corner__lines-horizontal">
          <div class="corner__lines-horizontal-item"></div>
          <div class="corner__lines-horizontal-item"></div>
        </div>

        <div class="corner__lines-vertical">
          <div class="corner__lines-vertical-item"></div>
          <div class="corner__lines-vertical-item"></div>
        </div>
        <div
          v-for="corner in corners"
          :key="corner.variable"
          :class="'corner__item-' + corner.variable"
          class="corner__item"
          @pointerdown="resizeCut($event, corner.variable)"
        >
          <img src="~@/assets/img/cut-corner.png" alt="" class="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageCropper",
  data: () => ({
    corners: [
      {
        variable: "tl",
        name: "Top Left",
      },
      {
        variable: "tr",
        name: "Top Right",
      },
      {
        variable: "bl",
        name: "Bottom Left",
      },
      {
        variable: "br",
        name: "Bottom Right",
      },
    ],
    boxCropStyles: {
      height: "100%",
      width: "100%",
      position: "relative",
      overflow: "hidden",
      background: "rgba(0,0,0,.5)",
    },
    boxShadowCropStyles: {
      position: "absolute",
      transform: "translate(0,0)",
    },
    boxShadow: {
      x: 0,
      y: 0,
    },
    isResizing: false,
    minimun: 100,
    resizeType: null,
    rotate: 0,
    mouse: {
      x: 0,
      y: 0,
    },
    original: {
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    },
    translate: {
      x: 0,
      y: 0,
    },
    originalImage: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    croppedImage: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
  }),
  computed: {
    isCrop() {
      return this.$store.getters["post/navControls/isCrop"];
    },
    currentImageStyles() {
      return this.$store.getters["post/drag/selectedStylesCut"];
    },
    selectedStylesCropCut() {
      return this.$store.getters["post/navControls/selectedStylesCrop"];
    },
    isCropSaved() {
      return this.$store.getters["post/navControls/isCropSaved"];
    },
    selectedDetail() {
      return this.$store.getters["post/image/selectedDetail"];
    },
    selected() {
      return this.$store.getters["post/image/selected"];
    },
  },
  mounted() {
    this.boxCropStyles.height = `${this.selectedDetail.height}px`;
    this.boxCropStyles.width = `${this.selectedDetail.width}px`;
  },
  methods: {
    resizeCut($event, type) {
      // Si mueve una de las esquinas
      $event.preventDefault();

      const element = this.$refs.boxCrop;
      this.isResizing = true;
      this.mouse.x = $event.pageX;
      this.mouse.y = $event.pageY;
      this.resizeType = type;

      this.original.width = parseFloat(this.boxCropStyles.width.replace("px", "")); // ancho
      this.original.height = parseFloat(this.boxCropStyles.height.replace("px", "")); // altura

      this.original.x = element.getBoundingClientRect().left; // position inicial x
      this.original.y = element.getBoundingClientRect().top; // position inicial y

      this.boxShadowCropStyles.width = `${this.selectedDetail.width}px`; // ancho
      this.boxShadowCropStyles.height = `${this.selectedDetail.height}px`; // altura

      window.addEventListener("pointermove", this.moveResize);
      window.addEventListener("pointerup", this.endResize);
    },
    moveResize(e) {
      if (this.isResizing) {
        let width;
        let height;

        const mouseMovement = {
          x: e.movementX,
          y: e.movementY,
        };

        const currentHeight = parseFloat(
          this.currentImageStyles.height.replace("px", "")
        );
        const currentWidth = parseFloat(this.currentImageStyles.width.replace("px", ""));

        const currentWidthBox = parseFloat(this.boxCropStyles.width.replace("px", ""));
        const currentHeightBox = parseFloat(this.boxCropStyles.height.replace("px", ""));

        switch (this.resizeType) {
          case "tl": // TOP-LEFT
            if (this.translate.x + mouseMovement.x >= 0) {
              this.translate.x += mouseMovement.x;
              width = currentWidthBox - mouseMovement.x;
              this.boxShadow.x -= mouseMovement.x;
            }

            if (this.translate.y + mouseMovement.y >= 0) {
              this.translate.y += mouseMovement.y;
              height = currentHeightBox - mouseMovement.y;
              this.boxShadow.y -= mouseMovement.y;
            }
            break;
          case "tr":
            if (this.translate.y + mouseMovement.y >= 0) {
              this.translate.y += mouseMovement.y;
              height = currentHeightBox - mouseMovement.y;
              this.boxShadow.y -= mouseMovement.y;
            }
            // if(e.pageX <= this.mouse.x) {
            width = currentWidthBox + mouseMovement.x;
            // }
            break;
          case "bl":
            if (this.translate.x + mouseMovement.x >= 0) {
              this.translate.x += mouseMovement.x;
              width = currentWidthBox - mouseMovement.x;
              this.boxShadow.x -= mouseMovement.x;
            }

            height = currentHeightBox + mouseMovement.y;
            break;
          case "br":
            if (currentWidth - this.original.width > e.pageX - this.mouse.x) {
              width = this.original.width + (e.pageX - this.mouse.x);
            } else {
              width = currentWidth;
            }

            if (currentHeight - this.original.height > e.pageY - this.mouse.y) {
              height = this.original.height + (e.pageY - this.mouse.y);
            } else {
              height = currentHeight;
            }
            break;
        }

        this.boxShadowCropStyles.transform = `translate(${this.boxShadow.x}px, ${this.boxShadow.y}px)`;

        this.originalImage.height = currentHeight;
        this.originalImage.width = currentWidth;
        this.originalImage.x = this.boxShadow.x;
        this.originalImage.y = this.boxShadow.y;

        this.croppedImage.height = height;
        this.croppedImage.width = width;

        if (height > this.minimun) {
          this.boxCropStyles.height = `${height}px`;
          this.boxCropStyles.transform = `translate(${this.translate.x}px, ${this.translate.y}px)`;
          this.croppedImage.y = this.translate.y;
        }

        if (width > this.minimun) {
          this.boxCropStyles.width = `${width}px`;
          this.boxCropStyles.transform = `translate(${this.translate.x}px, ${this.translate.y}px)`;
          this.croppedImage.x = this.translate.x;
        }
      }
    },
    endResize($event) {
      $event.preventDefault();
      const element = this.$refs.boxCrop;
      const width = parseFloat(
        getComputedStyle(element, null).getPropertyValue("width").replace("px", "")
      );
      const height = parseFloat(
        getComputedStyle(element, null).getPropertyValue("height").replace("px", "")
      );

      console.log(width, height, element);

      // this.$store.dispatch('post/drag/RESIZE', {
      //   uuid: this.selected,
      //   width,
      //   height,
      //   x: this.translateX,
      //   y: this.translateY,
      //   rotate: this.rotate,
      // })

      this.isResizing = false;

      window.removeEventListener("pointerup", this.endResize);
      window.removeEventListener("pointermove", this.moveResize);
    },
  },
  watch: {
    isCropSaved() {
      if (this.isCropSaved) {

        this.$store.dispatch("post/drag/RESIZE", {
          uuid: this.selected,
          x: this.croppedImage.x + this.selectedDetail.x,
          y: this.croppedImage.y + this.selectedDetail.y,
          width: this.croppedImage.width,
          height: this.croppedImage.height,
          rotate: this.rotate,
        });

        // this.$store.dispatch("post/drag/RESIZE", {
        //   uuid: this.selected,
        //   x: this.croppedImage.x + this.selectedDetail.x,
        //   y: this.croppedImage.y + this.selectedDetail.y,
        //   width: this.croppedImage.width,
        //   height: this.croppedImage.height,
        //   transform: '',
        //   rotate: this.rotate
        // });

        // ESTILOS DEL SELECTOR
        this.$store.commit("post/drag/SET_STYLES_SELECTED", {
          transform: `translate(${this.croppedImage.x + this.selectedDetail.x}px, ${
            this.croppedImage.y + this.selectedDetail.y
          }px) rotate(${this.rotate}deg)`,
          height: `${this.croppedImage.height}px`,
          width: `${this.croppedImage.width}px`,
        });

        this.$store.dispatch("post/navControls/CROP_SAVED", false);
        this.$store.dispatch("post/navControls/CROP", false);
      }
    },
  },
};
</script>

<style lang="scss">
.corner {
  outline: 2px solid #00a5ff;
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  display: block;

  &__container {
    border: 1px solid #00a5ff;
  }

  &__lines {
    &-horizontal,
    &-vertical {
      position: absolute;
      top: 0;
      height: 100%;
      left: 0;
      width: 100%;
      display: flex;
    }

    &-horizontal {
      justify-content: space-evenly;
      flex-direction: column;

      &-item {
        width: 100%;
        height: 1px;
        background-color: #00a5ff;
      }
    }

    &-vertical {
      justify-content: space-evenly;
      &-item {
        height: 100%;
        width: 1px;
        background-color: #00a5ff;
      }
    }
  }

  &__item {
    position: absolute;
    height: 17px;
    width: 17px;
    cursor: n-resize;
    pointer-events: auto;

    &-icon {
      width: 100%;
      height: 100%;
      min-width: 32px;
      min-height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-tl {
      top: -3px;
      left: -3px;
      cursor: nwse-resize;
    }
    &-tr {
      top: -3px;
      right: -3px;
      transform: rotate(90deg);
      cursor: nesw-resize;
    }
    &-bl {
      bottom: -3px;
      left: -3px;
      transform: rotate(270deg);
      cursor: nesw-resize;
    }
    &-br {
      bottom: -3px;
      right: -3px;
      transform: rotate(180deg);
      cursor: nwse-resize;
    }
  }
}
</style>
