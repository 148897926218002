<template>
  <div class="main__row">
    <div style="outline: none">
      <div class="main__row_header pl-5">
        <template v-if="selected && !isBlocked">
          <text-controls
            v-if="selectedDetail.typeItem === 'text'"
          ></text-controls> 
          <nav-controls
            v-if="selectedDetail.typeItem === 'image'"
          ></nav-controls>
          <shape-controls
            v-if="selectedDetail.typeItem === 'shape'"
          ></shape-controls>
        </template>
        <template v-else>
          <div
            style="
              position: absolute;
              max-width: 300px;
              display: flex;
              align-items: center;
            "
          >
            <div class="header-name" style="max-width: 280px;">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="My post name"
                autocomplete="off"
                :class="!changeInputBorder ? 'title-post' : 'title-border'"
                class="title-post-input"
                v-model="postName"
                :disabled="disabled"
              />
              <div
                :class="!changeInputBorder ? 'title-post' : 'title-border'"
                class="title-post-input title-post-help"
              >
                {{ postName }}
              </div>
            </div>
            <v-btn
              @click="changeIcon"
              icon
              style="position: relative"
            >
              <v-icon
                small
                :class="!changeInputBorder ? 'icon-edit' : 'icon-check'"
              >
                {{ icon }}
              </v-icon>
            </v-btn>
          </div>
        </template>
        <div class="main__row_header_redo-undo pl-0 pr-0">
          <v-btn icon class="back" @click="undo()" :disabled="canUndo">
            <v-icon class="back-icon" color="#5D6B88">$back-step</v-icon>
          </v-btn>

          <v-btn icon @click="redo()" :disabled="canRedo" class="next">
            <v-icon class="next-icon" color="#5D6B88">$next-step</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
    <isc-modal-alert
      v-model="showAlert"
      :title="title"
      :paragraph="paragraph"
      :icon="icon"
      :iconColor="iconColor"
      @clicked="resultModalAlerts"
      @input="closeIconX"
      :showBtn="showBtn"
      :showBtnClose="showBtnClose"
      :showMainIcon="showMainIcon"
    />
  </div>
</template>

<script>
// import FilterCanvas from '@/components/dropdowns/FilterCanvas'
// import AdjustCanvas from '@/components/dropdowns/AdjustCanvas';
import ShapeControls from "@/components/controls/ShapeControls";
import NavControls from "@/components/controls/NavControls";
import TextControls from "@/components/controls/TextControls";
import { mapActions, HandleRespMixin, IscModalAlert } from "@design/styleguide";

export default {
  name: "PostHeader",
  components: {
    ShapeControls,
    // FilterCanvas,
    // AdjustCanvas,
    NavControls,
    TextControls,
    IscModalAlert
  },
  mixins: [ HandleRespMixin ],
  data() {
    return {
      name: "",
      disabled: false,
      icon: "mdi-lead-pencil",
      postId: null,
      changeInputBorder: false,
      triggerService: false,
      postName: "",
    };
  },
  computed: {
    selected() {
      return this.$store.getters["post/image/selected"];
    },
    selectedDetail() {
      return this.$store.getters["post/image/selectedDetail"];
    },
    isBlocked() {
      return this.$store.getters["post/sideControls/isBlocked"];
    },
    namePost() {
      return this.$store.getters["post/main/name"];
    },
    idPost() {
      return this.$store.getters["post/main/id"];
    },
    isSelectedText() {
      return this.$store.getters["post/drag/selectedText"];
    },
    canRedo() {
      return this.$store.getters["global/redoundo/canRedo"];
    },
    canUndo() {
      return this.$store.getters["global/redoundo/canUndo"];
    },
    isHover() {
      return this.$store.getters["post/drag/hover"];
    },
    isTextSelected() {
      return this.$store.getters["post/canvas/isTextSelected"];
    },
  },
  watch: {
    postName(name) {
      this.setNamePost(name);
    },
    namePost(name) {
      this.postName = name;
    },
  },
  methods: {
    ...mapActions({
      setNamePost: "post/main/SET_NAME",
    }),
    redo() {
      this.$store.commit("post/image/SET_SELECTED_IMAGE", null);
      this.$store.dispatch("global/redoundo/REDO");
      this.$store.dispatch('post/drag/DISABLED_ALL_EDITING_TEXT');
    },
    undo() {
      this.$store.commit("post/image/SET_SELECTED_IMAGE", null);
      this.$store.dispatch("global/redoundo/UNDO");
      this.$store.dispatch('post/drag/DISABLED_ALL_EDITING_TEXT');
    },
    changeIcon() {
      if (!this.postId) return;
      if (this.icon === "mdi-lead-pencil") {
        this.icon = "mdi-checkbox-marked-circle";
        this.disabled = false;
        this.changeInputBorder = true;
        this.triggerService = true;
      } else {
        const isName = this.validationName();
        if (!isName) return;
        this.disabled = true;
        this.icon = "mdi-lead-pencil";
        this.changeInputBorder = false;
        if (this.triggerService) {
          const post = { name: this.postName };
          this.$store.dispatch("post/saved/UPDATE_NAME", {
            id: this.postId,
            post,
            responseFunc: this.responseMessageGraphql
          });
          this.triggerService = false;
        }
      }
    },
    validationName() {
      if (!this.postName) {
        //* this function comes from HandleRespMixin
        this.errorModal("It is required Post's name");
        return false;
      } else return true;
    },
  },
  mounted() {
    if (this.namePost) this.postName = this.namePost;
    if (this.idPost) {
      this.disabled = true;
      this.postId = this.idPost;
    } else {
      this.disabled = false;
    }
  },
};
</script>

<style scoped lang="scss">
.main {
  &__row {
    flex-shrink: 0;
    z-index: 1;
    background: #ffffff;
    &_header {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      background: var(--0NEIBA);
      border-bottom: 1px solid var(--OHlWbw);
      height: 48px;
      padding: 8px;
      box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
      &_redo-undo {
        flex-grow: 1;
        text-align: center;
        .back {
          &-icon {
            width: 12px;
            height: 16px;
          }
        }
        .next {
          &-icon {
            width: 12px;
            height: 16px;
          }
        }
      }
    }
  }

  &__content {
    flex: 1;
    z-index: 0;
    min-height: 0;
    contain: content;
    position: relative;

    .post_lienzo__content {
      overflow: scroll;
      height: 100%;
      user-select: none;
      -webkit-user-drag: none;
    }
  }
}
.header {
  &-name {
    //margin-left: 12px;
    position: relative;
    max-width: 400px;
    position: relative;
    overflow: hidden;
    .title-post {
      font-family: Oxygen;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 16px;
      outline: none;

      &-input {
        width: 100%;
        height: 16px;
        position: absolute;
        left: 0;
        top: 0;
      }

      &-help {
        pointer-events: none;
        opacity: 0;
        white-space: pre;
        position: relative;
        min-width: 102px;
      }

      &::placeholder {
        color: #96a0b8;
      }
    }
    // 96A0B8
    .title-border {
      font-family: Oxygen;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 21px;
      // width: 117px;
      outline: none;
      border-bottom: 1px solid;
    }
    .icon-check {
      font-size: 18px !important;
      color: #00a5ff;
    }
    .icon-edit {
      color: "#5D6B88";
      font-size: 18px !important;
    }
  }
}
::v-deep.v-btn > .v-btn__content .v-icon.icon-check {
  color: #00a5ff;
}
</style>
