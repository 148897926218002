<template>
  <div>
    <template v-if="!isCrop">
      <filter-canvas></filter-canvas>
      <adjust-canvas></adjust-canvas>
      <flip-canvas></flip-canvas>
    </template>
    <crop-canvas></crop-canvas>
  </div>
</template>

<script>
import FilterCanvas from '@/components/dropdowns/FilterCanvas'
import AdjustCanvas from '@/components/dropdowns/AdjustCanvas'
import FlipCanvas from '@/components/dropdowns/FlipCanvas'
import CropCanvas from '@/components/buttons/CropCanvas'

export default {
	name: "NavControls",
  components: { FilterCanvas, AdjustCanvas, FlipCanvas, CropCanvas },
  data() {
    return {
      imgTrans: "",
      cropFlag: false,
      cropEdit: false,
    };
  },
  computed: {
    selected() {
      return this.$store.getters["post/image/selected"];
    },
    isCrop() {
      return this.$store.getters['post/navControls/isCrop']
    },
  },
};
</script>
