<template>
  <v-text-field
      class="search-input"
      hide-details
      dense
      outlined
      placeholder="Search"
      v-model="value"
      @keydown.enter="sendArray($event)"
  >
    <template v-slot:append>
      <v-icon color="cpText" size="13">$search</v-icon>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "SearchInput",
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data(){
    return {
      value: '',
			take: 15,
			page: 1,
    }
  },
	watch: {
		type(){
			this.value = '';
			this.$emit('trigger-find', true);
		},
		value(val){
			if(val === '') this.sendArray();
		}
	},
  methods:{
    sendArray(event){
      if(this.value === ""){
				this.$emit('trigger-find', true);
        if(!event)
          this.$store.dispatch('post/'+this.type+'/FIND', 
            { take: this.take,  page: this.page }
          );
      }else{
				this.$emit('trigger-find', false);
        let strings = this.value.split(",");
        let keywords = strings.map(item=>item.trim())
        this.$store.dispatch('post/'+this.type+'/FIND_WITH_KEYWORDS',{ keywords });
      }
    }
  }
};
</script>
<style lang="scss">
.search-input.v-text-field {
  height: 35px;
  width: 156px;
  font-size: 12px;
  color: #5d6b88;
  > .v-input__control {
    > .v-input__slot {
      min-height: 35px !important;
    }
  }
  fieldset {
    border-color: #c5d0e4 !important;
  }
  input {
    max-height: 30px;
    color: #5d6b88;
  }

  & > .v-input__control > .v-input__slot {
    background: #ffffff;
  }
  .v-input__append-inner {
    margin-top: 0px !important;
    align-self: center;
  }
}
</style>
