<template>
  <div>
    <div
      class="image-horizontal__content wrapper-scroll scroll-base container_background_scroll"
      v-on:scroll="handleScroll"
      ref="container"
    >
      <color-input />
      <div class="horizontal-masonry horizontal-masonry--h">
        <figure
          v-for="(item, i) in backgrounds.items"
          :key="i"
          class="horizontal-masonry-brick horizontal-masonry-brick--h background-item"
        >
          <img
            class="horizontal-masonry-img"
            :src="urlThumbnail(item.thumbnails).location"
            alt="item.name"
            draggable="false"
            @click="startClick($event, item)"
          />
        </figure>
        <template v-if="isLoading">
          <v-skeleton-loader
            class="horizontal-masonry-brick horizontal-masonry-brick--h"
            type="image"
            v-for="i in take"
            :key="i"
            dark
            tile
          ></v-skeleton-loader>
        </template>
      </div>

    </div>
  </div>
</template>
<script>
import ColorInput from "@/components/inputs/ColorInput";
import { mapGetters } from '@design/styleguide';

export default {
  components: {
    ColorInput,
  },
  name: "PostBackground",
  props: {
    trigger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      base_url_images_thumb:
        process.env.BASE_URL_IMAGES_THUMBS ||
        "https://d2wk4yjqh165z7.cloudfront.net/fit-in/0x154/img",
      take: 15,
      page: 0,
      addBackgrounds: [],
      loadingScroll: false,
      showBackgrounds: true,
      firstAmountOfBackgrounds: 0,
      activeScroll: true,
      currentBackgrounds: []
    };
  },
  computed: {
    ...mapGetters({
      isLoading: 'post/background/isLoading',
      backgroundsByKeywords: 'post/background/backgroundsByKeywords',
      defaultBackgrounds: 'post/background/backgrounds'
    }),
    backgrounds() {
      return {
        scrollable: this.defaultBackgrounds.length ? true : false,
        items: this.showBackgrounds ? this.currentBackgrounds : this.backgroundsByKeywords,
        hasMore: this.defaultBackgrounds.length ? true : false
      }
    }
  },
  mounted() {
    this.loadMoreItems()
  },
  watch: {
    trigger(value) {
      if (value) this.showBackgrounds = true;
      else {
        this.$store.commit("post/background/RESET_BACKGROUNDS_BY_KEYWORDS");
        this.showBackgrounds = false;
        this.resetVariablesScroll();
      }
    },
  },
  methods: {
    handleScroll() {
      const container = this.$refs.container;
      const lastChild = container.querySelector('.background-item:last-of-type')

      if(!lastChild) return

      const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                  this.loadMoreItems();
              }
            });
          },
          {
            root: null,
            threshold: 0.9, 
          }
        );
        observer.observe(lastChild);
    },
    async loadMoreItems() {
      if(!this.isLoading && this.backgrounds.hasMore && this.showBackgrounds || this.page === 0) {  

        this.page++;
        await this.$store.dispatch("post/background/FIND", {
          take: this.take,
          page: this.page,
        });

        this.currentBackgrounds.push(...this.defaultBackgrounds)

        const container = this.$refs.container;
        
        if(!container) return

        if(container.scrollHeight <= container.clientHeight) this.loadMoreItems()
      }
    },
    urlThumbnail(images) {
      return images.find((item) => item.quality === "SCREEN_4");
    },
    startClick(evt, image) {
      image.type = "backgroundI";
      this.$store.dispatch("post/background/ADD_BACKGROUND_IN_CANVAS", image);
      // this.$store.dispatch("post/SAVE_CHANGES");
    },
    resetVariablesScroll() {
      this.addBackgrounds = [];
      this.firstAmountOfBackgrounds = 0;
      // this.page = 1;      
    }
  },
};
</script>

<style lang="scss" scoped>
.img_wrapper {
  width: 154px;
  height: 154px;
  display: inline-block;
  overflow: hidden;
  img {
    margin: 4px;
    cursor: pointer;
  }
}
.margin-image {
  margin: 0 6px 0 3px;
}
</style>
