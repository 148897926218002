
<template>
  <div class="text-edit" 
    :style="stylesBoxText(text)"
    >
    <div
      :style="{
        // height: `${text.original.height}px`,
        // width: `${text.original.width}px`,
        transform: `scaleX(${text.transform.scale.x}) scaleY(${text.transform.scale.y})`,
        transformOrigin: '0px 0px',
        cursor: isMoving ? 'move' : '',
        border: isSelected() ? '1px dashed #00A5FF' : 'none',
      }"
      style="transform: translate(0px, 0px) rotate(0deg)"
      class="content-text-input"
    >
      <div
        :style="{
          position: 'relative',
          // top: '6px',
          // left: '6px',
          // width: `${text.width}px`,
          overflow: 'hidden',
        }"
        :class="{ opacity_0 : text.isEditing, no_select: !text.isEditing }"
        :id="'textedit' + index"
        v-html="text.default"
        class="ql-editor"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: Object,
      default: {},
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      positionX: 0,
      positionY: 0,
    };
  },
  computed: {
    // zoom() {
    //   return this.$store.getters["post/zoom/zoom"];
    // },
    imagesSeletedByBox() {
      return this.$store.getters["post/drag/imagesSeletedByBox"];
    },
    AllImages() {
      return this.$store.getters["post/drag/images"];
    },
    // selectedImage() {
    //   return this.$store.getters["post/image/selected"];
    // },
  },
  mounted() {},
  methods: {
    /**
     ** Generates the styles for the text box based on the given item.
     ** This when You click in the box, it triggers the action which only affects to text. 
     * @param {Object} item - The item object containing the properties for styling.
     * @return {Object} The styles object for the text box.
     */
    stylesBoxText(item) {
      // let filter = null;
      // const currentFilters = [];

      // if (!Array.isArray(item.filter) && item.filter) {
      //   Object.keys(item.filter).forEach((key, index) => {
      //     switch (key) {
      //       case "sepia":
      //       case "grayscale":
      //       case "invert":
      //       case "brightness":
      //       case "contrast":
      //       case "saturate":
      //         currentFilters.push(`${key}(${item.filter[key]})`);
      //         break;
      //       case "blur":
      //         currentFilters.push(`${key}(${item.filter[key] * 100}px)`);
      //         break;
      //     }
      //   });
      //   filter = currentFilters.join(" ");
      // }

      //* Add flip here
      let flip = "";
      if (item.flip) {
        flip = `scale(${item.flip[0].value}, ${item.flip[1].value})`;
      }
     
      const zIndex = this.AllImages.findIndex(({ uuid }) => uuid === item.uuid);

      return {
        transform: `translate(${item.x}px, ${item.y}px) rotate(${item.rotate}deg)${flip}`,
        // height: `${item.height}px`,
        height:'auto',
        width: `${item.width}px`,
        //filter,
        zIndex,
        opacity: item.transparency,
      };
    },
    isSelected() {
      return this.imagesSeletedByBox.includes(this.text.uuid);
    },
    // mouseEnter(item) {
    //   if(item.uuid != this.selectedImage) {
    //     this.$store.dispatch('post/drag/UPDATE_HOVER_STYLES_SELECTED', {
    //       width: item.width,
    //       height: item.height,
    //       transform: {
    //         translate: {
    //           x: item.x,
    //           y: item.y,
    //         },
    //         rotate: item.rotate
    //       },
    //       uuid: item.uuid
    //     })
    //   }
    //   this.$store.dispatch('post/drag/UPDATE_HOVER', false);
    // },
  },
};
</script>
<style>
.text-edit {
  position: absolute;
}
.opacity_0{
  opacity: 0;
  /* transition: all 0.5s ease-out !important */
}
.text-edit-base{
  padding: 12px 15px;
}
</style>