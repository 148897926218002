var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.item)?_c('div',{ref:"fakeImage",staticClass:"image__box box-canvas pointer-event overflow-hidden",class:{ active: this.selected },style:([_vm.stylesBox(_vm.item)]),on:{"pointerdown":function($event){return _vm.start($event)},"pointerup":function($event){if($event.target !== $event.currentTarget)return null;return _vm.end.apply(null, arguments)}}},[(_vm.item.typeItem === 'image')?_c('div',{staticClass:"wh-100 p-relative"},[_c('div',{staticClass:"wh-100 trans-ease"},[_c('div',{ref:"realImage",staticClass:"p-relative image-canvas",class:{ active: _vm.selected, 'background-opacity': _vm.isCrop },style:({
          height: `${_vm.item.cropped.height}px`,
          width: `${_vm.item.cropped.width}px`,
          cursor: _vm.isMoving ? 'move' : '',
          transform: _vm.item.cropped
            ? `translate(${_vm.item.cropped.x}px, ${_vm.item.cropped.y}px) rotate(0deg)`
            : null,
          border: _vm.isSelected() ? '1px solid #00A5FF' : 'none',
        }),on:{"click":function($event){return _vm.$emit('clicked',_vm.item)}}},[_c('img',{staticClass:"image_lienzo",attrs:{"crossorigin":"anonymous","src":_vm.urlScreen(_vm.item.thumbnails).location,"draggable":"false","alt":_vm.item.name}})])])]):_vm._e(),(_vm.item.typeItem === 'text')?_c('div',[_c('div',{style:(_vm.item.styles),attrs:{"contenteditable":""}},[_vm._v(" "+_vm._s(_vm.item.default)+" ")])]):_vm._e(),(_vm.item.typeItem === 'shape')?_c('div',[_c('div',{class:{ active: this.selected },staticStyle:{"transform":"translate(0px, 0px) rotate(0deg)"},style:({
        height: `${_vm.item.height}px`,
        width: `${_vm.item.width}px`,
        cursor: _vm.isMoving ? 'move' : '',
      })},[_c('span',{style:({
          stroke: `${_vm.item.stroke}`,
          strokeWidth: `${_vm.item.strokeWidth}`,
        }),domProps:{"innerHTML":_vm._s(_vm.item.figure)}})])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }