// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/scss/fonts/phosphate/PhosphateRRSolid.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Abhaya+Libre&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Seaweed+Script&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Economica&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Andada+Pro&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Antonio&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Arapey&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Archivo&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Assistant&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Barlow&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Basic&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Be+Vietnam&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bilbo&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Blinker&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Boogaloo&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bowlby+One&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bubbler+One&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bungee&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Bebas Neue&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "@font-face{font-family:Phosphate;font-style:normal;font-display:auto;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\")}", ""]);
// Exports
module.exports = exports;
