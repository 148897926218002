<template>
  <div
    class="image-horizontal__content wrapper-scroll scroll-base container_image_scroll"
    v-on:scroll="handleScroll"
    ref="container"
  >
      <div
        class="img_wrapper template-item"
        v-for="template in templates.items"
        :key="template.uuid"
        @click="chooseTemplate(template)"
      >
        <v-img
          :src="template.finalImage"
          aspect-ratio="1"
          :alt="template.name"
          class="ma-1"
          style="cursor: pointer"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>
    <template v-if="isLoading">
      <v-skeleton-loader
        class="img_wrapper ma-1"
        width="146"
        height="146"
        type="image"
        v-for="i in take"
        :key="i"
        dark
        tile
      ></v-skeleton-loader>
    </template>
  </div>
</template>
<script>
export default {
  components: {},
  name: "PostTemplate",
  props: {
    trigger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      base_url_images_thumb:
        process.env.BASE_URL_IMAGES_THUMBS ||
        "https://d2wk4yjqh165z7.cloudfront.net/fit-in/0x154/img",
      take: 10,
      page: 0,
      activeScroll: true,
      image: null,
      currentTemplates: []
    };
  },

  computed: {
    isLoading() {
      return this.$store.getters['post/templates/isLoading']
    },
    templates() {
      const templates = this.$store.getters["post/templates/templates"]
      const templatesByKeywords = this.$store.getters["post/templates/templatesByKeywords"]
      
      this.currentTemplates.push(...templates)

      return {
        scrollable: templates.length ? true : false,
        items: this.currentTemplates.length ? this.currentTemplates : templatesByKeywords,
        hasMore: templates.length ? true : false
      }
    }
  },
  watch: {
    trigger(value) {
      if (value) this.activeScroll = true;
      else {
        this.$store.commit("post/templates/RESET_TEMPLATES_BY_KEYWORDS");
        this.activeScroll = false;
        this.resetVariablesScroll();
      }
    },
  },
  mounted() {
      this.loadMoreItems()
  },
  methods: {
    handleScroll() {
      const container = this.$refs.container;
      const lastChild = container.querySelector('.template-item:last-of-type')

      if(!lastChild) return

      const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                  this.loadMoreItems();
              }
            });
          },
          {
            root: null,
            threshold: 0.9, 
          }
        );
        observer.observe(lastChild);
    },
    async loadMoreItems() {
      if(!this.isLoading && this.templates.hasMore || this.page === 0) {  

          this.page++;
          await this.$store.dispatch("post/templates/FIND", {
            take: this.take,
            page: this.page,
          });

        const container = this.$refs.container;

        if(container.scrollHeight <= container.clientHeight) this.loadMoreItems()
      }
    },
    chooseTemplate(template) {
      delete template.id;
      this.$store.dispatch("post/main/LOAD_POST_CANVAS", template);
    },
    //* Reset variables to default values when the user leave keyword searching
    resetVariablesScroll() {
      this.currentTemplates = [];
      this.page = 1;
    }
  },
};
</script>

<style lang="scss" scoped>
.img_wrapper {
  width: 154px;
  height: 154px;
  display: inline-block;
  overflow: hidden;
  img {
    margin: 4px;
    cursor: pointer;
  }
}
</style>
<!-- Sharing the same style to other modules, no scope -->
<style lang="scss">
.loader-container {
  height: 25vh; /* Set the height of the container to the full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__progress {
    &--text {
      margin: 10px;
      font-size: 15px;
    }
  }
}
</style>
