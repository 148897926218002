<template>
  <div class="menu__typography d-flex flex-column" :class="{ menuShow: isMenuFontsOpen }">
    <div>
      <div class="d-flex justify-space-between py-3 pr-2">
        <div class="white--text"><h3>Typography</h3></div>
        <div
          class="white--text font-weight-bold pa-2 cursor-pointer"
          @click="closeMenu()"
        >
          <i class="fas fa-times"></i>
        </div>
      </div>
      <div class="mt-3 mb-5 pb-3 position-relative">
        <input
          class="input__search"
          type="text"
          name=""
          id=""
          v-model="searchFonts"
          @input="filterFonts"
          placeholder="Find your favorite.."
        />
        <div v-if="searchFonts.length > 1" class="btn_clear cursor-pointer"><span @click="clearFilter()"><i class="fas fa-trash"></i></span></div>
      </div>
      <div class="mb-5 mt-5 slide_filter">
        <template>
          <v-sheet
            class="mx-auto"
            max-width="700"
          >
            <v-slide-group
              
              show-arrows
            >
              <v-slide-item
                v-for="(item ,i) in slide_filter_items"
                :key="i"
                v-slot="{ active, toggle }"
              >
              <div @click="setFilterByOption(item.name)">

                <v-btn
                  class="mx-2 btn_slider_filter"
                  :input-value="active"
                  active-class="active"
                  :class="{'active': item.name == filterSelected}"
                  depressed
                  rounded
                  
                >
                <span :class="item.cssClass">
                  {{ item.name }}
                </span>
                </v-btn>
              </div>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </template>
      </div>
    </div>
    <!-- <v-divider></v-divider> -->

    <div class="mt-3 mb-3 ">
      <h4 class="white--text mb-3">
        <i class="fas fa-check-double"></i
        ><span class="ml-2">Typography Selected</span>
      </h4>
      <div>
        <!-- <div v-for="(fonts,i) in typographyList" :key="i" class="py-2">
          <span class="white--text" :style="{ fontFamily: fonts.font }">
            {{ fonts.text }}
          </span>
        </div> -->
        <div class="">
        <div v-for="(fontsSelected,i) in typographySelectedList" :key="i" class="py-2 cursor-pointer d-flex justify-space-between align-center">
          <span class="white--text" :class="`ql-font-${fontsSelected}`">
            {{ fontsSelected }}
          </span>
          <i class="fas fa-check mr-3 checkTypography"></i>
        </div>
      </div>
      </div>
    </div>
    <div class="mb-5 opacity-hr">
      <hr>

    </div>
    <h4 class="white--text mb-3">
      <i class="fas fa-stream"></i><span class="ml-2">Typography List</span>
    </h4>
    <div class="mt-3 mb-2 flex-grow-1 flex-shrink-0 overflow-y-auto position-relative">
      <div class="position-absolute">

        <div class="FontListContainer">
          <div v-for="(fonts,i) in fontsListFilt" :key="i" class="py-2 cursor-pointer fontsListItem" @click="setFontFamily(fonts.font)">
            <span class="white--text" :class="`ql-font-${fonts.font}`">
              {{ fonts.text }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fontsDesignList } from "@design/styleguide";
export default {
  name: "CustomMenuTypography",
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      filterSelected: "",
      searchFonts: "",
      fontsListFilt:[],
      slide_filter_items: [
        {
          name:"Shadow",
          cssClass: "ql-font-Tobago-Poster-Shadow"
        },
        {
          name:"Bold",
          cssClass: "ql-font-Roboto-bold"
        },
        {
          name:"Italic",
          cssClass: "ql-font-Roboto-italic"
        },
        {
          name:"Black",
          cssClass: "ql-font-Roboto-black"
        },
        {
          name:"Semi Bold",
          cssClass: "ql-font-Poppins-semi-bold"
        },
        
      ]
    };
  },
  computed: {
    model: {
      get() {
        console.log("emiteeee");
        return this.default;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    selectedImage() {
      return this.$store.getters["post/image/selectedDetail"];
    },
    isMenuFontsOpen() {
      return this.$store.getters["post/drag/isOpenFontMenu"];
    },
    fontsList() {
      return fontsDesignList;
    },
    quillGetSelection() {
      return this.$store.getters["post/drag/quillGetSelection"];
    },
    quillGetLastSelection() {
      return this.$store.getters["post/drag/quillGetLastSelection"];
    },
    quillEditor() {
      return this.$store.getters["post/drag/quillEditor"];
    },
    selected() {
      return this.$store.getters["post/image/selected"];
    },
    selectedText() {
      let selectTextValue = this.$store.getters["post/drag/selectedText"];
      if (selectTextValue != null) {
        const {
          styles: { fontWeight, fontSize, fontFamily, color },
          key,
        } = selectTextValue;

        return {
          fontSize: parseFloat(fontSize.replace("px", "")),
          fontWeight,
          fontFamily,
          color: color == "#000" ? "#000000FF" : color,
          key,
        };
      }
    },
    typographySelectedList() {
      return this.$store.getters["post/drag/typographySelectedList"];
    },
    paragraphsList() {
      return this.$store.getters["post/drag/paragraphsList"];
    },
    counterClick() {
      return this.$store.getters["post/drag/countClick"];
    },
  },
  mounted() {
    this.fontsList.map((font)=> this.fontsListFilt.push(font));
    // console.log('menu typography--------------->', this.fontsListFilt);
  },
  watch: {
    isMenuFontsOpen(newValue, oldValue) {
      if(!newValue) {
        this.filterSelected = "";
        this.searchFonts = "";
        this.fontsListFilt = [];
        this.fontsList.map((font)=> this.fontsListFilt.push(font));
        // this.quillEditor.setSelection(null);
      }else{
        let quillWrap = document.getElementById('wrapQuill');
        let lengthQuillText = this.quillEditor.getLength();
        // console.log("watch menu typography open", quillWrap);
        if (this.quillGetSelection == null) {
          if(this.counterClick == 1){
            this.$store.dispatch('post/drag/ACTIVE_TO_TEXTEDIT', true);
            this.$store.dispatch("post/canvas/IS_TEXT_SELECTED", false);
            quillWrap.style.zIndex = '999';
            this.$store.dispatch('post/drag/TEXT_CLICK_COUNT', 0);
            this.quillEditor.setSelection(0, lengthQuillText);
          }
        }
      }
      // console.log("watch n",  newValue);
      // console.log("watch o", oldValue,);
    },
    quillGetSelection(newValue, oldValue){
      // console.log('watch cambios', newValue, this.quillEditor.getContents(), this.paragraphsList);
    },
    typographySelectedList(newValue,oldValue){
        // console.log('Lista de tipografias seleccionadas', newValue);
        
    }
  },
  methods: {
    setFilterByOption(option){
      // console.log('filter by option', option);
      this.filterSelected = "";
      this.filterSelected = option;
      this.searchFonts = option;
      this.filterFonts();
    },
    closeMenu() {
      this.$store.dispatch("post/drag/CHANGE_IS_OPEN_MENU_FONTS", false);
    },
    clearFilter() {
      this.filterSelected = "";
      this.searchFonts = "";
      this.fontsListFilt = [];
      this.fontsList.map((font)=> this.fontsListFilt.push(font));
    },
    filterFonts() {
      let __fontsList = null;
      if(this.searchFonts.length <= 0){
        this.fontsListFilt = [];
        this.fontsList.map((font)=> this.fontsListFilt.push(font));
      }else{
        __fontsList = this.fontsList.filter(font => font.text.toLowerCase().includes(this.searchFonts.toLowerCase()) );
        this.fontsListFilt = [];
        __fontsList.map((font)=> this.fontsListFilt.push(font));
      }
    },
    setFontFamily(font){
      let range = this.quillGetLastSelection;
      let length_ = this.quillEditor.getLength();
      let deltaTrue = this.paragraphsList.filter((p) => p.change == true);
      deltaTrue.map((element)=>{
        if(element.insert.length > 1) this.quillEditor.formatText(element.start, element.end, "font", font);
      });
      // 
      this.$store.dispatch("post/textControls/CHANGE_FONT_FAMILY_IN_TEXT", {
        fontFamily: font,
        uuid: this.selected,
        index: this.selectedText.key,
      });
      // console.log('set font Family', this.selected, range);
      if(range) this.quillEditor.setSelection(range.index, range.length);
      // this.quillEditor.formatText(index, length, type, value);
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-select {
  position: relative;
  width: 187px;
  text-align: left;
  outline: none;
  height: 40px;
  line-height: 47px;

  .selected {
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid #c5d0e4;
    color: #000000de;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 14px;
    box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62);
    border-radius: 3px;

    &:after {
      position: absolute;
      content: "";
      top: 20px;
      right: 1em;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-color: rgba(93, 107, 136, 1) transparent transparent transparent;
    }
  }

  .items {
    color: #fff;
    border-radius: 0px 0px 6px 6px;
    overflow: auto;
    border-right: 1px solid #c5d0e4;
    border-left: 1px solid #c5d0e4;
    border-bottom: 1px solid #c5d0e4;
    position: absolute;
    background-color: #ffffff;
    left: 0;
    right: 0;
    z-index: 1;
    font-size: 14px;
    max-height: 300px;
  }
}

.custom-select .items div {
  color: rgb(0, 0, 0);
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #d7f1ff;
}

.selectHide {
  display: none;
}
.menu__typography {
  height: 99%;
  width: calc(100% + 2px);
  background-color: #222d3a;
  position: absolute;
  left: -100%;
  // margin-left: -20px;
  visibility: hidden;
  top: 0;
  transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.3s ease;
  z-index: 5;
  &.menuShow {
    z-index: 5;
    transition: left 0.3s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.3s ease;
    left: 0;
    // margin-left: -2px;
    visibility: visible;
  }
  .input__search {
    background-color: #ffffff;
    width: 99%;
    box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62);
    border-radius: 3px;
    height: 40px;
    padding: 0 5px;
    outline: none;
    font-size: 14px !important;
    &::placeholder{
      opacity: .8;
      font-size: 13px;
      padding-left: 5px;
    }
  }
  .FontListContainer {
    overflow-y: auto;
    // height: calc(100vh - 283px);
    .fontsListItem{
      // transition: .3s cubic-bezier(.25,.8,.5,1);
      &:hover{
        span{
          color: #00A5FF !important;
        }
      }
    }
  }
  .opacity-hr {
    opacity: .2;
  }
  .checkTypography{
    color: #00A5FF !important;
    font-size: 14px;
  }
}

.btn_clear{
  position: absolute;
  right: 15px;
  top: 10px;
  i{
    color: rgba(0, 165, 255,.8);
  }
}
</style>
