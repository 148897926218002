import Vue from 'vue'
Vue.mixin({
  methods: {
    getImageDimensions(file){
      let _URL = window.URL || window.webkitURL;
      return new Promise((resolve, reject)=>{
        let img = new Image();
        img.src = _URL.createObjectURL(file);
        img.onload = () => resolve({
          w:img.width,
          h:img.height
        })
        img.onerror = reject
      });
    }
  }
})
