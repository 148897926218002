<template>
  <div class="custom-color-picker">
      <div class="button-color" :style="{ backgroundColor: color }" @click="openColorPicker"></div>

      <div class="content-color" :class="{ 'active': isOpened }">
          <v-tabs v-model="tab">
            <v-tab>Solid</v-tab>
            <v-tab>Gradient</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item :transition="false">
                <isc-color-picker v-model="colorSelected"></isc-color-picker>
            </v-tab-item>
            <v-tab-item :transition="false">
                <isc-gradient-picker v-model="gradientSelected"></isc-gradient-picker>
            </v-tab-item>
        </v-tabs-items>
      </div>
  </div>
</template>

<script>
import { IscColorPicker }  from '@design/styleguide'
import IscGradientPicker from '@/components/inputs/IscGradientPicker'

export default {
    components: {
        IscColorPicker,
        IscGradientPicker
    },
    props: {
        color: {
            type: String,
            default: '#000'
        }
    },
    data() {
        return {
            tab: null,
            isOpened: false,
            colorSelected: '#000',
            gradientSelected: '#0000000'
        }
    },
    methods: {
        openColorPicker() {
            this.isOpened = !this.isOpened
            // console.log()
        }
    }
}
</script>

<style lang="scss" scoped>
    $primary: #00A5FF;
    $grey: #5D6B88;
    $tabs-bar-height: 10px;

    .custom-color-picker {
        display: flex;
        align-items: center;
        height: 36px;
        width: 36px;
        justify-content: center;
    }
    .button-color {
        height: 20px;
        width: 20px;
        background-color: red;
        border-radius: 2px;
        cursor:pointer;
    }

    .content-color {
        position: absolute;
        top: 36px;
        display: none;
        padding: 25px;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);

        ::v-deep .v-tabs-bar { 
            height: auto;

            .v-tabs-slider-wrapper {
                display: none;
            }

            .v-tab {
                text-transform: capitalize;
                font-weight: bold;

                padding: 0;
                min-width: auto;
                margin: 5px;

                &:first-child {
                    margin-right: 10px;
                }

                &:hover::before, &:focus::before {
                    opacity: 0;
                }

                .v-ripple__container {
                    display: none;
                }
            }
        }


        &__tabs {
            list-style: none;
            display: flex;
            padding: 0;

            &-item {
                
                &:first-child {
                    margin-right: 30px;
                }
            }

            &-link {
                text-decoration: none;
                color: $grey;
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;

                &.active {
                    color: $primary;
                }
            }
        }

        &.active {
            display: block;
        }
    }
</style>