<template>
  <div class="guide-lines"
    :style="{
        width: size.width * zoom.transform + 'px',
        height: size.height * zoom.transform + 'px',
        transformOrigin: '0 0',
      }"
  >
    <div class="guide-lines__main-h" v-if="isGuideLinesActiveHorizontal"></div>
    <div class="guide-lines__main-v" v-if="isGuideLinesActiveVertical"></div>
    <div 
      class="guide-lines__secondary" 
      v-for="(line, index) in isGuideLinesActiveSecondary" 
      :key="index"
      :style="{
        transform: `translate(${line.x * zoom.transform}px, ${line.y * zoom.transform}px)`,
        width: `${line.width * zoom.transform}px`,
        height: `${line.height * zoom.transform}px`
      }"
    ></div>
  </div>
</template>

<script>

export default {
  computed: {
    zoom() {
      return this.$store.getters["post/zoom/zoom"];
    },
    size() {
      return this.$store.getters['post/zoom/post'];
    },
    isGuideLinesActiveHorizontal() {
      return this.$store.getters["post/guideLines/isActiveHorizontal"];
    },
    isGuideLinesActiveVertical() {
      return this.$store.getters["post/guideLines/isActiveVertical"];
    },
    isGuideLinesActiveSecondary() {
      return this.$store.getters["post/guideLines/isActiveSecondary"];
    },
  }
}
</script>

<style lang="scss" scoped>
.guide-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &__main-h, &__main-v {
    // background-color: #FF595C;
    position: absolute;
  }

  &__main {
    &-h {
      border-bottom: 1px dashed #FF595C;
      height: 0;
      width: 100%;
      top: calc(50% - 0.5px);
      transform: translateY(-50%);
    }

    &-v {
      border-right: 1px dashed #FF595C;
      width: 0;
      height: 100%;
      left: calc(50% - 0.5px);
      transform: translateX(-50%);
    }
  }

  &__secondary {
    position: absolute;
    background-color: #FF595C;
  }
}
</style>
