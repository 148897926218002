<template>
	<div>
		<v-btn
			color="primary"
			class="btn-file "
			:loading="uploading"
			@click="onPickFile"
			> 
				<v-file-input
					ref="fileInput"
					class="d-none"
					:accept="accept"
					:multiple="multiple"
					@change="previewFiles"
				/>
				{{ text }}
		</v-btn>
		<span v-if="uploading">{{ progress }}%</span>
	</div>
</template>

<script>
import { mapGetters, UploadFunctionsMixin  } from "@design/styleguide";
export default {
	name: 'CpBtnInputFile',
	mixins: [UploadFunctionsMixin],
	data() {
    return {
      items: [],
      url: null,
      error: '',
      disabled: true,
			uploads: [],
			statesSuccess: [],
			files: [],
			count: 0
    };
  },
	props: {
    label: String,
    text: {
      type: String,
      default: 'Upload',
    },
    accept: {
      type: String,
      default: () => {
        return 'image/*';
      },
    },
    multiple: Boolean,
		progress: {
			type: Number,
			default: () => {
				return 0; 
			}
		},
    uploading: {
			type: Boolean,
      default: () => {
				return false;
      },
    },
		max: {
      type: Number,
      default: () => {
        return 3
      }
    }
  },
	computed: {
    ...mapGetters({
      resetInputFile: "global/util/getResetRefInput",
    }),
  },
	watch: {
		resetInputFile(val){
			if(val) {
        this.$refs.fileInput.value = null;
				this.$store.commit('global/util/CHANGE_ACTIVE_REFERENCE_INPUT', false);
      }
		}
	},
  methods: {
    onPickFile() {
      const { fileInput } = this.$refs;
      const { input } = fileInput.$refs;
      input.click();
    },
    previewFiles(event) {
			this.files = event;
			if(this.files.length < 0) return;
			let isValidateMaxUploads = this.validateMaxUploadsFiles(this.max, this.files);
      if(isValidateMaxUploads) return;
			this.validationImages(this.files);	
    },
		validationImages(files){
			this.count = 0;
			this.statesSuccess = [];
			this.uploads = [];
      for (const file of files) {
        this.uploads.push(file)
				let success = true
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (evt) => {
          let img = new Image();
          img.onload = () => {
            success = img.width >= 160 && img.height >= 160;
						this.validationProcess(success);
          };
          img.src = evt.target.result;
        };
      }

		},
		validationProcess(success){
			this.statesSuccess.push(success);
			if(!success){ 
				this.count++
				let msg ='Upload images larger than 160 * 160 pixels.';
        this.$emit('msg-error', msg);
			}
			if((this.statesSuccess.length == this.files.length) && this.count == 0 ){
				this.$emit('files', this.uploads);
			}
		}, 
  },
};
</script>

<style lang="scss" scoped>
	.btn-file {
		width: 82px;
		height: 35px;
		font-family: Oxygen;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 16px;
	}
	.v-btn:not(.v-btn--round).v-size--default {
			padding: 9px 20px 10px;
		}
</style>