//import IscHtmlEditable from '@/components/inputs/IscHtmlEditable'
export default {
  name: "LienzoImage",
  components: {
    //IscHtmlEditable,
  },
  props: {
    item: Object,
    isCrop: Boolean
  },
  computed: {
    AllImages() {
      return this.$store.getters['post/drag/images'];
    },
    selected() {
      return this.$store.getters['post/image/selected'] === this.item.uuid;
    },
    selectedImage() {
      return this.$store.getters["post/image/selected"];
    },
    resized() {
      return this.$store.getters['post/drag/resize']
    },
    rotation() {
      return this.$store.getters['post/drag/rotate']
    },
    selectedDetail() {
      return this.$store.getters['post/image/selectedDetail'];
    },
    isBlocked() {
      return this.$store.getters['post/sideControls/isBlocked']
    },
    isTextSelected() {
      return this.$store.getters['post/canvas/isTextSelected']
    },
    selectedStylesResize() {
      return this.$store.getters['post/drag/selectedStylesResize'];
    },
    zoom() {
      return this.$store.getters['post/zoom/zoom'];
    },
    selectedCrop() {
      return this.$store.getters['post/drag/selectedCrop']
    },
    imagesSeletedByBox() {
      return this.$store.getters["post/drag/imagesSeletedByBox"];
    },
    currentZoom() {
      return this.$store.getters['post/zoom/post'];
    },
    images() {
      const images = this.$store.getters['post/drag/images'];
      return images.filter(({ typeItem }) => typeItem != 'text')
    },
  },
  data: () => ({
    document: {
      nodeType: 'document',
      content: [
        {
          nodeType: 'paragraph',
          content: [
            {
              nodeType: 'text',
              value: 'Hello world!',
              marks: [],
            },
          ],
        },
      ],
    },
    box: {},
    isMoving: false,
    isDragging: false,
    startX: 0,
    startY: 0,
    isFocused: false,
    prevMouseMovement: {
      x: 0,
      y: 0
    },
    position: {
      x: 0,
      y: 0,
    },
  }),
  mounted() {
    window.addEventListener('pointerup', this.end);
  },
  watch: {
    resized(item) {
      if (item.uuid === this.item.uuid) {
        this.item.x = item.x;
        this.item.y = item.y;
        this.item.width = item.width;
        this.item.height = item.height;
      }
    },
    rotation(item) {
      if (item.uuid === this.item.uuid) {
        this.item.rotate = item.rotate;
      }
    }
  },
  methods: {
    urlScreen(images) {
      let type;
      if (this.item.width <= 160 && this.item.height <= 250) {
        type = 'SCREEN_4';
      } else if (this.item.width <= 587 && this.item.height <= 880) {
        type = 'SCREEN_3';
      } else if (this.item.width <= 3235 && this.item.height <= 4860) {
        type = 'SCREEN_2';
      } else {
        type = 'SCREEN_1'
      }
      return images.find((item) => item.quality === type);
    },
    stylesBox(item) {
      let filter = null

      const currentFilters = []

      if (!Array.isArray(item.filter) && item.filter) {
        Object.keys(item.filter).forEach((key, index) => {

          switch (key) {
            case 'sepia':
            case 'grayscale':
            case 'invert':
            case 'brightness':
            case 'contrast':
            case 'saturate':
              currentFilters.push(`${key}(${item.filter[key]})`);
              break;
            case 'blur':
              currentFilters.push(`${key}(${item.filter[key] * 100}px)`)
              break;
          }
        })
        filter = currentFilters.join(' ')
      }

      let flip = ''

      if (item.flip) {
        flip = `scale(${item.flip[0].value}, ${item.flip[1].value})`
      }

      const zIndex = this.AllImages.findIndex(({ uuid }) => uuid === item.uuid);
      return {
        transform: `translate(${item.x}px, ${item.y}px) rotate(${item.rotate}deg) ${flip}`,
        height: `${item.height}px`,
        width: `${item.width}px`,
        filter,
        zIndex,
        opacity: item.transparency
      }
    },
    start($event) {
      if (this.isBlocked || this.item.blocked) return;
      if (this.isTextSelected) return
      if (this.isCrop) return;

      const element = $event.target
      this.isMoving = true;
      this.isDragging = true;
      element.setPointerCapture($event.pointerId);
      this.startX = $event.pageX;
      this.startY = $event.pageY;

      this.$store.dispatch('post/image/SELECT_IMAGE_IN_CANVAS', this.item.uuid);
      this.$store.dispatch('post/drag/UPDATE_STYLES_IN_SELECTION_RESIZE', {
        width: this.item.width,
        height: this.item.height,
        transform: {
          translate: {
            x: this.item.x,
            y: this.item.y
          },
          rotate:this.item.rotate
        }
      })
      this.$store.dispatch('post/drag/UPDATE_ROTATE', this.item.rotate)

      this.prevMouseMovement.x = $event.clientX;
      this.prevMouseMovement.y = $event.clientY;

      this.position = {
        x: this.selectedStylesResize.transform.translate.x,
        y: this.selectedStylesResize.transform.translate.y,
      };

      window.addEventListener('pointerup', this.end);
      window.addEventListener('pointermove', this.move);

    },
    move(event) {
      // if (this.isMoving && this.isDragging) {
      //   const positions = {
      //     x: $event.pageX,
      //     y: $event.pageY
      //   }
      //   this.translate(positions, $event.target);
      // }
      let width = this.selectedStylesResize.width;
      let height = this.selectedStylesResize.height;

      this.position.x -= (this.prevMouseMovement.x - event.clientX)
      this.position.y -= (this.prevMouseMovement.y - event.clientY)

      this.prevMouseMovement.x = event.clientX;
      this.prevMouseMovement.y = event.clientY;

      

      this.$store.dispatch('post/drag/UPDATE_STYLES_IN_SELECTION_RESIZE', {
        width: this.item.width,
        height: this.item.height,
        transform: {
          translate: {
            x: this.position.x,
            y: this.position.y
          },
          rotate:this.item.rotate
        },

      })

      const cropped = this.item.cropped ? {
        x: this.item.cropped.x,
        y: this.item.cropped.y,
        width: this.item.cropped.width,
        height: this.item.cropped.height,
      } : null



      this.$store.dispatch('post/drag/RESIZE', {
        uuid: this.item.uuid,
        width,
        height,
        x: this.position.x,
        y: this.position.y,
        rotate: this.item.rotate,
        cropped
      })

      this.$store.dispatch("post/image/UPDATE_IMAGE_SELECTED", this.selectedImage);
    },
    end($event) {
      this.$store.dispatch('post/guideLines/ACTIVE', false);
      const element = $event.target
      element.releasePointerCapture($event.pointerId);
      this.isDragging = false;
      this.isMoving = false;
      window.removeEventListener('pointerup', this.end);
      window.removeEventListener('pointermove', this.move);
      // this.$store.dispatch('post/SAVE_CHANGES');
    },

    translate(positions) {
      window.requestAnimationFrame(() => {
        this._translateFromImage(positions);

        this.startX = positions.x;
        this.startY = positions.y;
      });
    },
    _translateFromImage(positions) {
      let move = {
        x: Math.floor(positions.x - this.startX),
        y: Math.floor(positions.y - this.startY)
      };

      this.item.x = this.item.x + move.x;
      this.item.y = this.item.y + move.y;
    },
    mouseEnter(item) {
      if (item.typeItem != 'text') this.$store.dispatch('post/canvas/IS_TEXT_SELECTED', false)

      this.$store.dispatch('post/drag/UPDATE_HOVER_STYLES_SELECTED', null);

      if(item.uuid != this.selectedImage) {
          this.$store.dispatch('post/drag/UPDATE_HOVER_STYLES_SELECTED', {
            width: item.width,
          height: item.height,
          transform: {
            translate: {
              x: item.x,
              y: item.y,
            },
            rotate: item.rotate
          },
          uuid: item.uuid
        })
      }

      this.$store.dispatch('post/drag/UPDATE_HOVER', false);
    },
    isEditable() {
      console.log('first');
    },
    isSelected() {
      return this.imagesSeletedByBox.includes(this.item.uuid);
    },
  }
}
