var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"guide-lines",style:({
      width: _vm.size.width * _vm.zoom.transform + 'px',
      height: _vm.size.height * _vm.zoom.transform + 'px',
      transformOrigin: '0 0',
    })},[(_vm.isGuideLinesActiveHorizontal)?_c('div',{staticClass:"guide-lines__main-h"}):_vm._e(),(_vm.isGuideLinesActiveVertical)?_c('div',{staticClass:"guide-lines__main-v"}):_vm._e(),_vm._l((_vm.isGuideLinesActiveSecondary),function(line,index){return _c('div',{key:index,staticClass:"guide-lines__secondary",style:({
      transform: `translate(${line.x * _vm.zoom.transform}px, ${line.y * _vm.zoom.transform}px)`,
      width: `${line.width * _vm.zoom.transform}px`,
      height: `${line.height * _vm.zoom.transform}px`
    })})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }