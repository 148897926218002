var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-edit",style:(_vm.stylesBoxText(_vm.text))},[_c('div',{staticClass:"content-text-input",staticStyle:{"transform":"translate(0px, 0px) rotate(0deg)"},style:({
      // height: `${text.original.height}px`,
      // width: `${text.original.width}px`,
      transform: `scaleX(${_vm.text.transform.scale.x}) scaleY(${_vm.text.transform.scale.y})`,
      transformOrigin: '0px 0px',
      cursor: _vm.isMoving ? 'move' : '',
      border: _vm.isSelected() ? '1px dashed #00A5FF' : 'none',
    })},[_c('div',{staticClass:"ql-editor",class:{ opacity_0 : _vm.text.isEditing, no_select: !_vm.text.isEditing },style:({
        position: 'relative',
        // top: '6px',
        // left: '6px',
        // width: `${text.width}px`,
        overflow: 'hidden',
      }),attrs:{"id":'textedit' + _vm.index},domProps:{"innerHTML":_vm._s(_vm.text.default)}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }