<template>
  <div class="post-content__canvas-selector" :style="{ transform: `scale(${zoom.transform})`, transformOrigin: '0 0', }">
    <ContentCropBackground />

    <div class="post-content__resize-tools-bg" :style="imageStylesCropped">
      <div
        class="post-content__background"
        :style="imageStylesBackgroundCropped"
      >
        <img :src="image.path" style="width: 100%; height: 100%" />
      </div>
    </div>

    <div
      class="post-content__resize-tools"
      :style="imageStylesCropped"
      ref="resizeOut"
    >
      <div
        class="post-content__resize-tl"
        @pointerdown="resize($event, 'top-left')"
      ></div>
      <div
        class="post-content__resize-tr"
        @pointerdown="resize($event, 'top-right')"
      ></div>
      <div
        class="post-content__resize-bl"
        @pointerdown="resize($event, 'bottom-left')"
      ></div>
      <div
        class="post-content__resize-br"
        @pointerdown="resize($event, 'bottom-right')"
      ></div>

      <div class="post-content__horizontal">
        <div class="post-content__horizontal-line"></div>
        <div class="post-content__horizontal-line"></div>
      </div>

      <div class="post-content__vertical">
        <div class="post-content__vertical-line"></div>
        <div class="post-content__vertical-line"></div>
      </div>

      <div
        class="post-content__resize-box"
        @pointerdown="movePosition($event)"
      ></div>
    </div>
  </div>
</template>

<script>
import ContentCropBackground from "./ContentCropBackground";
export default {
    name: "ContentCrop",
    components: {ContentCropBackground},
    data() {
        return {
            clicked: false,
            rotate: 0,
            position: {
                x: 0,
                y: 0,
            },
            prevMouseMovement: {
                x: 0,
                y: 0,
            },
            selectedCorner: null,
            boxCenter: {
                x: 0,
                y: 0,
            },
            size: {
                width: 0,
                height: 0,
            },
            sizeAvailable: {
                width: 0,
                height: 0,
            },
            positionCroppedBackground: {
                x: 0,
                y: 0,
            },
        };
    },
    computed: {
        selectedStylesCut() {
            return this.$store.getters["post/drag/selectedStylesCut"];
        },
        selected() {
            return this.$store.getters["post/image/selected"];
        },
        selectedImage() {
            return this.$store.getters["post/image/selectedDetail"];
        },
        imageStylesCropped() {
            const translate = `translate(${this.position.x}px,${this.position.y}px)`;
            const rotate = `rotate(${this.rotate}deg)`;
            const transform = [translate, rotate];
            return {
                width: this.size.width + "px",
                height: this.size.height + "px",
                transform: transform.join(" "),
            };
        },
        imageStylesBackgroundCropped() {
            const { width, height, cropped } = this.$store.getters["post/image/selectedDetail"];
            const translateX = this.positionCroppedBackground.x;
            const translateY = this.positionCroppedBackground.y;
            const translate = `translate(${translateX}px,${translateY}px)`;
            const transform = [translate];
            return {
                width: cropped.width + "px",
                height: cropped.height + "px",
                transform: transform.join(" "),
            };
        },
        image() {
            const { id, thumbnails, width, height, x, y, rotate, cropped } = this.$store.getters["post/image/selectedDetail"];
            const currentImage = thumbnails.find((item) => item.quality === "SCREEN_4");
            const translate = `translate(${x}px,${y}px)`;
            const rotateDeg = `rotate(${rotate}deg)`;
            const transform = [translate, rotateDeg];
            return {
                id,
                width,
                height,
                x,
                y,
                styles: {
                    width: width + "px",
                    height: height + "px",
                    transform: transform.join(" "),
                },
                path: currentImage.location,
                cropped,
                rotate,
            };
        },
        styles() {
            const { transform: { translate: { x, y }, rotate, }, width, height, } = this.$store.getters["post/drag/selectedStylesResize"];
            const translate = `translate(${x}px,${y}px)`;
            const rotateDeg = `rotate(${rotate}deg)`;
            const transform = [translate, rotateDeg];
            return {
                width: width + "px",
                height: height + "px",
                transform: transform.join(" "),
            };
        },
        selectedStylesResize() {
            return this.$store.getters["post/drag/selectedStylesResize"];
        },
        zoom() {
          return this.$store.getters['post/zoom/zoom'];
        },
    },
    created() {
        this.position.x = this.image.x;
        this.position.y = this.image.y;
        this.size.width = this.image.width;
        this.size.height = this.image.height;
        this.sizeAvailable.width = this.image.cropped.width;
        this.sizeAvailable.height = this.image.cropped.height;
        this.positionCroppedBackground.x = this.image.cropped.x
        this.positionCroppedBackground.y = this.image.cropped.y
        this.rotate = this.image.rotate;
    },
    methods: {
        resize(event, corner) {
            this.clicked = true;
            this.selectedCorner = corner;
            this.prevMouseMovement.x = event.clientX;
            this.prevMouseMovement.y = event.clientY;
            window.addEventListener("pointermove", this.onMouseMove);
            window.addEventListener("pointerup", this.onMouseUp);
        },
        onMouseMove(event) {
            if (this.clicked) {
                let position = {
                    x: this.position.x,
                    y: this.position.y,
                };
                let width = this.size.width;
                let height = this.size.height;

                const clientX = (width >= 50 || height >= 50)  ? event.clientX : 0;
                const clientY = (width >= 50 || height >= 50)  ? event.clientY : 0;


                // Movimiento del mouse
                let movedMouseX = this.prevMouseMovement.x - clientX;
                let movedMouseY = this.prevMouseMovement.y - clientY;

                const remainingSize = {
                    width: this.image.cropped.width - this.size.width,
                    height: this.image.cropped.height - this.size.height,
                };

                const positionAvailable = {
                    x: this.image.x + remainingSize.width + this.image.cropped.x,
                    y: this.image.y + remainingSize.height + this.image.cropped.y,
                };
                switch (this.selectedCorner) {
                    case "top-left":
                        width = this.size.width + movedMouseX;
                        height = this.size.height + movedMouseY;

                        if((width <= 50 || height <= 50)) return;
                        position.x -= movedMouseX;
                        position.y -= movedMouseY;
                        break;
                    case "top-right":
                        height = this.size.height + movedMouseY;
                        if (this.size.width - movedMouseX <= this.sizeAvailable.width) width = this.size.width - movedMouseX;
                      
                        if((width <= 50 || height <= 50)) return;

                        position.y -= movedMouseY;
                        break;
                    case "bottom-left":
                        width = this.size.width + movedMouseX;
                        if (this.size.height - movedMouseY <= this.sizeAvailable.height)
                            height = this.size.height - movedMouseY;

                        if((width <= 50 || height <= 50)) return;
                        position.x -= movedMouseX;
                        break;
                    case "bottom-right":
                        if (this.size.width - movedMouseX <= this.sizeAvailable.width) width = this.size.width - movedMouseX;
                        if (this.size.height - movedMouseY <= this.sizeAvailable.height) height = this.size.height - movedMouseY;
                        break;
                    default:
                        if (position.x - movedMouseX <= positionAvailable.x)
                            position.x -= movedMouseX;
                        if (position.y - movedMouseY <= positionAvailable.y)
                            position.y -= movedMouseY;
                }
                this.prevMouseMovement.x = event.clientX;
                this.prevMouseMovement.y = event.clientY;
                
                if (position.x >= (this.image.x + this.image.cropped.x)) this.position.x = position.x;
                if (position.y >= (this.image.y + this.image.cropped.y)) this.position.y = position.y;

                this.sizeAvailable.width = this.image.width - (this.position.x - this.image.x);
                this.sizeAvailable.height = this.image.height - (this.position.y - this.image.y);

                this.positionCroppedBackground.x = (this.position.x - this.image.x - this.image.cropped.x) * -1;
                this.positionCroppedBackground.y = (this.position.y - this.image.y - this.image.cropped.y) * -1;


                if (width <= 50 || height <= 50) return;
                if (position.x >= (this.image.x + this.image.cropped.x)) this.size.width = width;
                if (position.y >= (this.image.y + this.image.cropped.y)) this.size.height = height;

                this.$store.dispatch("post/drag/UPDATE_STYLES_IN_SELECTED_CROP", {
                    size: {
                        width: this.image.cropped.width,
                        height: this.image.cropped.height,
                    },
                    position: {
                        x: this.position.x,
                        y: this.position.y,
                    },
                    positionBackground: {
                        x: this.positionCroppedBackground.x,
                        y: this.positionCroppedBackground.y,
                    },
                    sizeBackground: {
                        width: this.size.width,
                        height: this.size.height,
                    },
                });
            }
        },
        onMouseUp() {
            this.clicked = false;
            window.removeEventListener("pointermove", this.onMouseMove);
            window.removeEventListener("pointerup", this.onMouseUp);
        },
        movePosition() {
            this.selectedCorner = null;
            this.clicked = true;
            this.prevMouseMovement.x = event.clientX;
            this.prevMouseMovement.y = event.clientY;
            window.addEventListener("pointermove", this.onMouseMove);
            window.addEventListener("pointerup", this.onMouseUp);
        },
        rotation() {
            const box = this.$refs.resizeOut;
            let boxBoundingRect = box.getBoundingClientRect();
            this.boxCenter = {
                x: boxBoundingRect.left + boxBoundingRect.width / 2,
                y: boxBoundingRect.top + boxBoundingRect.height / 2,
            };
            window.addEventListener("pointermove", this.rotationMove);
            window.addEventListener("pointerup", this.rotationEnd);
        },
        rotationMove(event) {
            let angle = Math.atan2(event.pageX - this.boxCenter.x, -(event.pageY - this.boxCenter.y)) *
                (180 / Math.PI) -
                180;
            this.$store.dispatch("post/drag/EVERY_ROTATE", {
                uuid: this.selected,
                rotate: angle,
            });
            this.rotate = angle;
            this.$store.dispatch("post/drag/UPDATE_ROTATE", angle);
            // if (this.rotate !== degree) {
            //   this.rotate = degree;
            //   this.$store.dispatch('post/drag/EVERY_ROTATE', {
            //     uuid: this.selected,
            //     rotate: this.rotate,
            //   });
            //   this.$store.commit('post/drag/SET_STYLES_SELECTED', {
            //     transform: `translate(${this.translateX}px, ${this.translateY}px) rotate(${this.rotate}deg)`,
            //     height: `${this.originalH}px`,
            //     width: `${this.originalW}px`,
            //   })
            // }
        },
        rotationEnd() {
            this.$store.dispatch("post/drag/ROTATE", {
                uuid: this.selected,
                rotate: this.rotate,
            });
            window.removeEventListener("pointermove", this.rotationMove);
            window.removeEventListener("pointerup", this.rotationEnd);
        },
    },
    
};
</script>

<style lang="scss" scoped>
.post-content {
  &__rotate {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    color: #00a5ff;
    background-color: #fff;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      height: 18px;
      width: 3px;
      border-left: 1px dashed #00a6ff;
      top: -20px;
    }
  }

  &__resize {
    // &-main-bg {
    //   position: absolute;

    //   .post-content__background::before {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-color: rgba(0, 0, 0, 0.5);
    //   }
    // }

    &-tools-bg {
      overflow: hidden;
      position: absolute;
    }
    &-box {
      border: 1.5px solid #00a5ff;
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 10;
      cursor: move;
    }

    &-tl,
    &-tr,
    &-bl,
    &-br {
      height: 17px;
      width: 17px;
      pointer-events: auto;
      background: url("~@/assets/img/cut-corner.png");
      position: absolute;

      z-index: 20;
    }
    &-tl {
      left: 0;
      top: 0;
      cursor: nw-resize;
    }

    &-tr {
      right: 0;
      top: 0;
      cursor: ne-resize;
      transform: rotate(90deg);
    }

    &-bl {
      bottom: 0;
      left: 0;
      cursor: ne-resize;
      transform: rotate(270deg);
    }

    &-br {
      bottom: 0;
      right: 0;
      cursor: nw-resize;
      transform: rotate(180deg);
    }
  }

  &__horizontal,
  &__vertical {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  &__horizontal {
    flex-direction: column;
    &-line {
      height: 1px;
      width: 100%;
      background-color: #00a5ff;
    }
  }

  &__vertical {
    &-line {
      width: 1px;
      height: 100%;
      background-color: #00a5ff;
    }
  }
}
</style>