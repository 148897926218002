var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"mainBox",staticClass:"box",attrs:{"data-transfer":"none"},on:{"drop":function($event){return _vm.onDrop($event)},"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.onClicked(null)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[_c('div',{ref:"capture",staticClass:"box__content",style:({
      width: _vm.zoom.width + 'px',
      height: _vm.zoom.height + 'px',
      background: _vm.background,
    }),on:{"mousemove":_vm.handleMouseMove}},[_c('div',{staticClass:"box__wrapper",style:({
        width: _vm.size.width + 'px',
        height: _vm.size.height + 'px',
        transformOrigin: '0 0',
        transform: `scale(${_vm.zoom.transform})`,
      }),attrs:{"id":"box_select"},on:{"pointerdown":function($event){return _vm.clickToSelect($event)}}},[_c('div',{staticClass:"p_absolute-content",on:{"click":function($event){return _vm.onClicked('lienzo')}}}),_vm._l((_vm.images),function(image,i){return _c('div',{key:i,ref:"images",refInFor:true,class:'image-item-' + i},[_c('lienzo-image',{attrs:{"item":image,"isCrop":_vm.isCrop},on:{"focused":_vm.onHover,"clicked":_vm.clicked}})],1)}),_vm._l((_vm.textsEdit),function(text,i){return _c('div',{key:'text' + i,ref:"texts",refInFor:true,staticClass:"text__section"},[_c('text-edit-input',{attrs:{"text":text,"index":text.indexPosition}})],1)})],2),(_vm.isGuideLinesActive)?[_c('ContentGuideLines')]:_vm._e(),(_vm.selected || _vm.isHoverStyles)?[(!_vm.isCrop && !_vm.isTextSelected)?_c('ContentResize'):_vm._e(),(_vm.isCrop)?_c('ContentCrop'):_vm._e(),(_vm.isBlocked)?_c('ContentBlocked'):_vm._e()]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }