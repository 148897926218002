var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"color-area"},[_c('span',[_vm._v("Solid color")]),_c('div',{staticClass:"colors-wrap"},[_c('div',{staticClass:"colors_palette custom-palette",class:{ active: _vm.colorSolidPickerActived },on:{"click":_vm.openSolidPicker}},[_c('i',{staticClass:"fas fa-palette"})]),_vm._l((_vm.colors),function(color){return _c('div',{key:color.id,staticClass:"colors_palette static-palette",class:color.id === _vm.colorActived.id && 'backgroundC' === _vm.colorActived.tipo
            ? 'active'
            : '',style:({ background: color.background }),on:{"click":function($event){return _vm.clickColor(color)}}},[_vm._v(" "+_vm._s(color.active)+" ")])})],2),(_vm.colorSolidPickerActived)?_c('isc-color-picker',{staticClass:"solid-color-picker",nativeOn:{"click":function($event){return _vm.colorSolidPickerClicked.apply(null, arguments)}},model:{value:(_vm.colorSolidPicker),callback:function ($$v) {_vm.colorSolidPicker=$$v},expression:"colorSolidPicker"}}):_vm._e()],1),_c('div',{staticClass:"color-area mt-5"},[_c('span',[_vm._v("Gradient color")]),_c('div',{staticClass:"colors-wrap"},[_c('div',{staticClass:"colors_palette custom-palette",class:{ active: _vm.colorGradientPickerActived },on:{"click":function($event){_vm.colorGradientPickerActived = !_vm.colorGradientPickerActived;
          _vm.colorSolidPickerActived = false;}}},[_c('i',{staticClass:"fas fa-palette"})]),_vm._l((_vm.gcolors),function(gcolor){return _c('div',{key:gcolor.id,staticClass:"colors_palette static-palette",class:{
          active:
            _vm.colorActived.id == gcolor.id &&
            _vm.colorActived.tipo == 'backgroundL',
        },style:({ background: gcolor.background }),on:{"click":function($event){return _vm.clickColorLinear(gcolor)}}})})],2),_c('isc-gradient-picker',{directives:[{name:"show",rawName:"v-show",value:(_vm.colorGradientPickerActived),expression:"colorGradientPickerActived"}],staticClass:"gradient-color-picker",attrs:{"gradientColors":_vm.gradientColorsSel},nativeOn:{"click":function($event){return _vm.colorGradientPickerClicked.apply(null, arguments)}},model:{value:(_vm.colorGradientPicker),callback:function ($$v) {_vm.colorGradientPicker=$$v},expression:"colorGradientPicker"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }