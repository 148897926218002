<template>
  <div style="width:100%;">
    <label class="label--input">
      {{ label }}
    </label>
    <v-slider
      class="slider-porcent"
      v-model="model"
      hide-details
      dense
      :max="max"
      :min="min"
    >
      <template v-slot:append>
        <label class="label--input">{{ rounded(model) }} %</label>
      </template>
    </v-slider>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    label: {
      type: String,
      default: ""
    },
    max: {
      type: [String],
      default: "50"
    },
    min: {
      type: [String],
      default: "-50"
    }
  },
  computed: {
    model: {
      get() {
        return this.value * 100;
      },
      set(val) {
        this.$emit("input", val / 100);
      }
    }
  },
  methods: {
    rounded(val) {
      return Math.round(val * 100) / 100;
    }
  }
};
</script>

<style lang="scss">
.label--input {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
.slider-porcent {
  .v-input__append-outer {
    width: 40px;
    margin-left: 0px;
  }
}
</style>
