<template>
  <div :style="{ transform: `scale(${zoom.transform})`, transformOrigin: '0 0',height:'0px',width:'0px'}">
    <div class="post-content__blocked"  :style="styles">
      <div class="post-content__blocked-tools">
        <v-icon class="post-content__blocked-lock">fas fa-lock</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	name: "ContentBlocked", 
  computed: {
    zoom() {
      return this.$store.getters["post/zoom/zoom"];
    },
    selectedDetail() {
      return this.$store.getters['post/image/selectedDetail'];
    },
    styles() {
      const { width, height, x, y, rotate } = this.$store.getters['post/image/selectedDetail']

      const translate = `translate(${x}px,${y}px)`;
      const rotateDeg = `rotate(${rotate}deg)`;
      const transform = [translate, rotateDeg];

      return {
        width: `${width}px`,
        height: `${height}px`,
        // transformOrigin: '0 0', 
        transform: transform.join(' ')
      }
    }
  }
}
</script>

<style lang="scss">
  .post-content__blocked {
    // position: absolute;
    &-tools {
      border: 2px solid #00a5ff;
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      z-index: 10;
      // cursor: move;
    }

      &-lock {
        position: absolute !important;
        bottom: -16px;
        right: -16px;
        background-color: #fff;
        padding: 10px;
        border-radius: 50%;
        border: 1px solid #e0e0e0;
        font-size: 16px;
      }
  }
</style>