<template>
  <v-row>
    <v-col class="gradient__wrapper">
      <v-row>
        <v-col md="12" class="py-0 px-5">
          <div
            class="gradient__content"
            @mousemove="mousemovido($event)"
            @mouseup="mousealzado($event)"
          >
            <div
              class="gradient__item"
              v-for="(item, key) in gradientColors"
              :key="key"
              :class="{ active: itemSelected === key }"
              :style="{
                background: item.color,
                left: (item.percent * widthBase) / 100 + 'px',
              }"
              @mousedown="presionado($event, key, item)"
            ></div>
          </div>
          <div
            style="
              height: 18px;
              width: 250px;
              border: 1px solid #c5d0e4;
              border-radius: 23px;
              margin-left:5px
            "
            :style="{ background: barGradientBackground }"
            @mousedown="addColor($event)"
          ></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <isc-color-picker v-model="colorSelected"></isc-color-picker>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { IscColorPicker } from "@design/styleguide";
export default {
  components: { IscColorPicker },
  name: "IscGradientPicker",
  props: {
    value: [String],
    gradientColors: {
      type: Array,
    },
  },
  data() {
    return {
      colorSelected: "#FFFFFF",
      // gradientColors: [
      //   {
      //     color: "#FFFFFF",
      //     percent: 0,
      //   },
      //   {
      //     color: "#000000",
      //     percent: 100,
      //   },
      // ],
      clicks: 0,
      timer: null,
      delay: 700,
      offset: [0, 0],
      elementSelected: null,
      isDown: false,
      widthBase: 250,
      currentBackground: "",
      itemSelected: 0,
      barGradientBackground: "",
    };
  },
  created() {
    // this.colorSelected = this.gradientColors[0].color;
    this.loadGradient();
  },
  computed: {
    model: {
      get() {
        this.loadGradient();
        return this.value;
      },
      set(val) {
        //console.log("val", val);
        this.$emit("input", val);
      },
    },
  },
  methods: {
    addColor(e) {
      if (e.target !== e.currentTarget) return;
      this.gradientColors.push({
        color: "#17343BA6",
        percent: 60,
      });

      this.loadGradient();
    },
    loadGradient() {
      let colorsList = [];
      this.gradientColors.map((i) => {
        colorsList.push({
          color: i.color,
          percent: i.percent,
        });
      });

      colorsList = colorsList.sort((a, b) => a.percent - b.percent);

      let colors = [];
      colorsList.map((i) => {
        colors.push(`${i.color} ${i.percent}%`);
      });
      //console.log("colors", colors);
      this.currentBackground = `linear-gradient(${colors.join(",")}) `;

      this.barGradientBackground = `linear-gradient(to right, ${colors.join(
        ","
      )}) `;

      this.$emit("input", this.currentBackground);
    },
    presionado(e, key, { color }) {
      // TODO: Eliminar marker con doble clic
      // this.clicks++
      // if (this.clicks === 1) {
      //     this.timer = setTimeout( () => {
      //       this.clicks = 0
      //     }, this.delay);
      //   } else {
      //       this.gradientColors.splice(key, 1)
      //      clearTimeout(this.timer);
      //      this.clicks = 0;
      //      this.loadGradient();
      //   }

      this.colorSelected = color;
      this.itemSelected = key;
      this.isDown = true;
      this.elementSelected = e;
      this.offset = [
        this.elementSelected.target.offsetLeft - e.clientX,
        this.elementSelected.target.offsetTop - e.clientY,
      ];
    },
    mousealzado() {
      this.isDown = false;
    },
    mousemovido(e) {
      if (this.isDown) {
        const mousePosition = {
          x: e.clientX,
          y: e.clientY,
        };
        const pixeles = mousePosition.x + this.offset[0];
        if (pixeles > 0 && pixeles < this.widthBase) {
          this.elementSelected.target.style.left = `${pixeles}px`;

          const percentGradient = (pixeles * 100) / this.widthBase;

          this.gradientColors[this.itemSelected].percent = percentGradient;

          this.loadGradient();
        }
      }
    },
  },
  watch: {
    colorSelected: {
      immediate: true,
      handler() {
        if (this.gradientColors[this.itemSelected])
          this.gradientColors[this.itemSelected].color = this.colorSelected;
        this.loadGradient();
      },
    },
    gradientColors: {
      immediate: true,
      handler() {
        const first = this.gradientColors[0];
        if (first) this.colorSelected = first.color;
        // this.gradientColors[this.itemSelected].color = this.colorSelected;

        this.loadGradient();
      },
    },
  },
};
</script>

<style lang="scss">
.gradient__wrapper {
  max-width: 300px;
  border-radius: 6px;
  background: #fff;
  padding-top: 30px;
}
.gradient__content {
  height: 30px;
  width: 250px;
  position: relative;
}
.gradient__item {
  height: 11px;
  width: 11px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  &.active {
    &::before {
      border: 2px solid #00a5ff;
    }

    &::after {
      background-color: #00a5ff;
    }
  }
  &::before {
    content: "";
    position: absolute;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid #c5d0e4;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::after {
    content: "";
    position: absolute;
    height: 5px;
    width: 5px;
    background-color: #c5d0e4;
    clip-path: polygon(0 0, 50% 100%, 100% 0);
    left: 50%;
    transform: translateX(-50%);
    bottom: -7px;
  }
}
</style>