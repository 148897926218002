<template>
  <div class="post-content__resize-main-bg" :style="imageStylesCropped">
    <div class="post-content__background" :style="imageStylesBackgroundMain">
      <img :src="image.path" style="width: 100%; height: 100%" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentCropBackground",
  computed: {
    image() {
      const { id, thumbnails, width, height, x, y, rotate, cropped } = this.$store.getters["post/image/selectedDetail"];
      const currentImage = thumbnails.find(
        (item) => item.quality === "SCREEN_4"
      );
      const translate = `translate(${x}px,${y}px)`;
      const rotateDeg = `rotate(${rotate}deg)`;
      const transform = [translate, rotateDeg];
      return {
        id,
        width,
        height,
        x,
        y,
        styles: {
          width: width + "px",
          height: height + "px",
          transform: transform.join(" "),
        },
        path: currentImage.location,
        cropped,
        rotate,
      };
    },
    imageStylesCropped() {
        const translate = `translate(${this.image.x}px,${this.image.y}px)`;
        const rotate = `rotate(${this.image.rotate}deg)`;
        const transform = [translate, rotate];
        return {
            width: this.image.width + "px",
            height: this.image.height + "px",
            transform: transform.join(" "),
        };
    },
    imageStylesBackgroundMain() {
      const { width, height, cropped } =
        this.$store.getters["post/image/selectedDetail"];
      const translateX = cropped.x//  === 0 ? this.positionCroppedBackground.x : cropped.x;
      const translateY = cropped.y//  === 0 ? this.positionCroppedBackground.y : cropped.y;
      const translate = `translate(${translateX}px,${translateY}px)`;
      const transform = [translate];
      return {
        width: cropped.width + "px",
        height: cropped.height + "px",
        transform: transform.join(" "),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.post-content {
  &__resize {
    &-main-bg {
      position: absolute;

      .post-content__background::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
</style>