<template>
  <div class="shape container_image_scroll">
    <div v-if="showShapes && shapes.length > 0">
      <section
        class="shape__section"
        v-for="(group, index) in shapes"
        :key="index"
      >
        <div class="shape__box" @click="showHide(index)">
          <h3
            style="color: #ffffff; font-size: 12px; padding: 10px 0 10px 20px"
          >
            {{ group.name }}
          </h3>
          <v-icon
            style="color: #ffffff; font-size: 12px; padding: 10px 12px 10px 0"
            >fas fa-angle-down</v-icon
          >
        </div>
        <div v-if="!hidden[index]" class="items-content">
          <div v-for="(shape, i) in group.child" :key="i" class="shapes">
            <span
              v-html="shape.figure"
              @click="addShapeInCanvas(shape)"
              draggable
              @dragstart="dragStartShape($event, shape)"
              @dragend="dragEndShape"
            >
            </span>
          </div>
        </div>
      </section>
    </div>
    <div v-else-if="shapesByKeywords.length > 0" class="items-content">
      <div v-for="(shape, i) in shapesByKeywords" :key="i" class="shapes">
        <span
          v-html="shape.figure"
          @click="addShapeInCanvas(shape)"
          draggable
          @dragstart="dragStartShape($event, shape)"
          @dragend="dragEndShape"
        ></span>
      </div>
    </div>
		<div  v-else class="loader-container">
      <div v-if="showShapes" class="loader-container__progress">
        <div class="loader-container__progress--text">Getting Shapes</div>
        <v-progress-linear
          color="white"
          height="6"
          indeterminate
          rounded
        ></v-progress-linear>
      </div>
      <div v-else class="loader-container__progress">
				<div class="loader-container__progress--text">
          Sorry, we couldn't find any matching items
        </div>
			</div>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "PostShapes",
  props: {
    route: {
      type: String,
    },
    trigger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      image: null,
      showShapes: true,
      hidden: {},
    };
  },
  created() {
    this.$store.dispatch("post/shapes/FIND");
  },
  computed: {
    shapes() {
      return this.$store.getters["post/shapes/shapes"];
    },
    shapesByKeywords() {
      return this.$store.getters["post/shapes/shapesByKeywords"];
    },
    size() {
      return this.$store.getters["post/zoom/post"];
    },
  },
  watch: {
    trigger(value) {
      if (value) this.showShapes = true;
      else {
        this.$store.commit("post/shapes/RESET_SHAPES_BY_KEYWORDS");
        this.showShapes = false;
      }
    },
  },
  methods: {
    showHide(id) {
      this.$set(this.hidden, id, !this.hidden[id]);
    },
    addShapeInCanvas(item) {
      console.log("item ", item);
      // const parser = new DOMParser();
      // const doc = parser.parseFromString(this.shapeSvg, 'text/html').body.childNodes[0];

      const shape = {
        figure: item.figure,
        fillDefault: "#C5D0E4",
        strokeWidth: 0,
        stroke: "#C5D0E4",
        width: item.width * 4,
        height: item.height * 4,
        x: this.size.width / 2 - 100,
        y: this.size.height / 2 - 100,
        rotate: 0,
      };
      this.$store.dispatch("post/drag/ADD_SHAPE_IN_CANVAS", shape);
    },
    captureImage(event, image) {
      console.log(event, image);
      let img = new Image();
      img.src =
        "https://res.cloudinary.com/vuejsprojects/image/upload/c_scale,f_auto,q_auto,w_auto/v1/post/vue-draggable-1.jpg";
      this.image = img;
    },
    dragStartShape(event, shape) {
      event.dataTransfer.dropEffect = "move";
      event.dataTransfer.effectAllowed = "move";
      // event.dataTransfer.setDragImage(this.image, 75, 75)
      this.$store.commit("post/drag/DRAG_IMAGE_START", {
        ...shape,
        uuid: uuidv4(),
        fillDefault: "#C5D0E4",
        strokeWidth: 0,
        stroke: "#C5D0E4",
        figure: shape.figure,
        typeItem: "shape",
        width: shape.width * 4,
        height: shape.height * 4,
        rotate: 0,
      });
      this.image = null;
    },
    dragEndShape(event) {
      console.log("aaaa", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.shape {
  // height: 100%;
  //overflow-y: auto;
  &__section {
    margin-bottom: 8px;
  }
  &__box {
    background-color: #273444;
    display: flex;
    align-content: center;
    justify-content: space-between;
    border-radius: 2px;
    margin-bottom: 8px;
    cursor: pointer;
  }
}
.items-content {
  // display: flex;
  // flex-wrap: wrap;
  //justify-content: space-evenly;
  // margin: 8px 0;
  display: grid;
  gap: 8px;
  grid-column: 3;
  grid-template-columns: repeat(3, 1fr);
}

.shapes {
  // margin: 4px 2px;
  display: flex;
  width: 100px;
  height: 100px;
  background-color: #273444;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  & span {
    width: 62px;
    height: 62px;
  }
}
</style>
