<template>
  <div>
    <div
      v-if="activeScroll && addBackgrounds.length > 0"
      class="image-horizontal__content wrapper-scroll scroll-base container_background_scroll"
      v-on:scroll="handleScroll"
      ref="container"
    >
      <color-input />
      <div class="horizontal-masonry horizontal-masonry--h">
        <figure
          v-for="(item, i) in addBackgrounds"
          :key="i"
          class="horizontal-masonry-brick horizontal-masonry-brick--h"
        >
          <img
            class="horizontal-masonry-img"
            :src="urlThumbnail(item.thumbnails).location"
            alt="item.name"
            @click="startClick($event, item)"
          />
        </figure>
      </div>
    </div>
    <div
      v-else-if="backgroundsByKeywords.length > 0"
      class="image-horizontal__content wrapper-scroll mx2 scroll-base container_background_scroll"
    >
      <color-input />
      <div class="horizontal-masonry horizontal-masonry--h">
        <figure
          v-for="(item, i) in backgroundsByKeywords"
          :key="i"
          class="horizontal-masonry-brick horizontal-masonry-brick--h"
        >
          <img
            class="horizontal-masonry-img"
            :src="urlThumbnail(item.thumbnails).location"
            alt="item.name"
            @click="startClick($event, item)"
          />
        </figure>
      </div>
    </div>
    <div v-else>
      <div class="loader-container">
        <div v-if="activeScroll" class="loader-container__progress">
          <div class="loader-container__progress--text">
            Getting Backgrounds
          </div>
          <v-progress-linear
            color="white"
            height="6"
            indeterminate
            rounded
          ></v-progress-linear>
        </div>
        <div v-else class="loader-container__progress">
          <color-input />
          <div class="loader-container__progress--text">
            Sorry, we couldn't find any matching items
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ColorInput from "@/components/inputs/ColorInput";
export default {
  components: {
    ColorInput,
  },
  name: "PostBackground",
  props: {
    trigger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      base_url_images_thumb:
        process.env.BASE_URL_IMAGES_THUMBS ||
        "https://d2wk4yjqh165z7.cloudfront.net/fit-in/0x154/img",
      take: 15,
      page: 1,
      addBackgrounds: [],
      loadingScroll: false,
      firstAmountOfBackgrounds: 0,
      activeScroll: true,
    };
  },
  created() {
    this.$store.dispatch("post/background/FIND", {
      take: this.take,
      page: this.page,
    });
  },
  computed: {
    backgrounds() {
      return this.$store.getters["post/background/backgrounds"];
    },
    backgroundsByKeywords() {
      return this.$store.getters["post/background/backgroundsByKeywords"];
    },
  },
  watch: {
    backgrounds(backgrounds) {
      if (backgrounds.length > 0) {
        //this.activeScroll = true;
        backgrounds.forEach((el) => {
          this.addBackgrounds.push(el);
        });
      }
    },
    trigger(value) {
      if (value) this.activeScroll = true;
      else {
        this.$store.commit("post/background/RESET_BACKGROUNDS_BY_KEYWORDS");
        this.activeScroll = false;
        this.resetVariablesScroll();
      }
    },
  },
  methods: {
    handleScroll() {
      /* 
				scrollHeight: all height scroll
				scrollTop: height location where scroll is marked. 
				style.height: height of view where scroll works.
			*/
      const { scrollHeight, scrollTop, clientHeight } = this.$refs.container;
      const entireHeight = Math.round(scrollTop + clientHeight);
      this.loadingScroll = false;
      if (entireHeight >= scrollHeight && !this.loadingScroll) {
        const validationLengthBackgrounds = this.validationMoreBackgrounds();
        if (!validationLengthBackgrounds) return;
        this.addItem();
        this.loadingScroll = true;
      }
    },
    addItem() {
      this.page++;
      this.$store.dispatch("post/background/FIND", {
        take: this.take,
        page: this.page,
      });
    },
    validationMoreBackgrounds() {
      if (this.firstAmountOfBackgrounds === 0) {
        this.firstAmountOfBackgrounds = this.addBackgrounds.length;
        return true;
      }
      if (this.firstAmountOfBackgrounds < this.addBackgrounds.length) {
        this.firstAmountOfBackgrounds = this.addBackgrounds.length;
        return true;
      } else return false;
    },
    urlThumbnail(images) {
      return images.find((item) => item.quality === "SCREEN_4");
    },
    startClick(evt, image) {
      image.type = "backgroundI";
      this.$store.dispatch("post/background/ADD_BACKGROUND_IN_CANVAS", image);
      // this.$store.dispatch("post/SAVE_CHANGES");
    },
    resetVariablesScroll() {
      this.addBackgrounds = [];
      this.firstAmountOfBackgrounds = 0;
      this.page = 1;      
    }
  },
};
</script>

<style lang="scss" scoped>
.img_wrapper {
  width: 154px;
  height: 154px;
  display: inline-block;
  overflow: hidden;
  img {
    margin: 4px;
    cursor: pointer;
  }
}
.margin-image {
  margin: 0 6px 0 3px;
}
</style>
