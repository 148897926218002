<template>
  <div
    v-if="activeScroll && addImages.length > 0"
    class="image-horizontal__content wrapper-scroll scroll-base container_image_scroll"
    v-on:scroll="handleScroll"
    ref="container"
  >
    <div class="horizontal-masonry horizontal-masonry--h">
      <figure
        v-for="(item, i) in addImages"
        :key="i"
        class="horizontal-masonry-brick horizontal-masonry-brick--h"
      >
        <img
          class="horizontal-masonry-img"
          :src="urlThumbnail(item.thumbnails).location"
          alt="item.name"
          @click="startClick(item)"
          @pointerdown="
            captureImage($event, urlThumbnail(item.thumbnails).location)
          "
          @dragstart="startDrag($event, item)"
          @dragend="endDrag"
        />
      </figure>
    </div>
  </div>
  <div
    v-else-if="imagesByKeywords.length > 0"
    class="image-horizontal__content wrapper-scroll mx-2 scroll-base"
  >
    <div class="horizontal-masonry horizontal-masonry--h">
      <figure
        v-for="(item, i) in imagesByKeywords"
        :key="i"
        class="horizontal-masonry-brick horizontal-masonry-brick--h"
      >
        <img
          class="horizontal-masonry-img"
          @dragstart="startDrag($event, item)"
          @pointerdown="
            captureImage($event, urlThumbnail(item.thumbnails).location)
          "
          :src="urlThumbnail(item.thumbnails).location"
          alt="item.name"
          @click="startClick(item)"
          @dragend="endDrag"
        />
      </figure>
    </div>
  </div>
  <div v-else>
    <div class="loader-container">
      <div v-if="activeScroll" class="loader-container__progress">
        <div class="loader-container__progress--text">Getting Images</div>
        <v-progress-linear
          color="white"
          height="6"
          indeterminate
          rounded
        ></v-progress-linear>
      </div>
      <div v-else class="loader-container__progress">
				<div class="loader-container__progress--text">
          Sorry, we couldn't find any matching items
        </div>
			</div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "PostImages",
  data: () => ({
    image: null,
    take: 15,
    page: 1,
    addImages: [],
    loadingScroll: false,
    firstAmountOfImages: 0,
    activeScroll: true,
    base_url_images_thumb:
      process.env.BASE_URL_IMAGES_THUMBS ||
      "https://d2wk4yjqh165z7.cloudfront.net/fit-in/0x154/img",
  }),
  props: {
    trigger: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("post/images/FIND", {
      take: this.take,
      page: this.page,
    });
  },
  computed: {
    view() {
      return this.$store.getters["post/zoom/post"];
    },
    images() {
      return this.$store.getters["post/images/images"];
    },
    imagesByKeywords() {
      return this.$store.getters["post/images/imagesByKeywords"];
    },
  },
  watch: {
    images(images) {
      if (images.length > 0) {
        //this.activeScroll = true;
        images.forEach((el) => {
          this.addImages.push(el);
        });
      }
    },
    trigger(value) {
      if (value) this.activeScroll = true;
      else {
        this.$store.commit("post/images/RESET_IMAGES_BY_KEYWORDS");
        this.activeScroll = false;
        this.resetVariablesScroll();
      }
    },
  },
  methods: {
    handleScroll() {
      /* 
				scrollHeight: all height scroll
				scrollTop: height location where scroll is marked. 
				style.height: height of view where scroll works.
			*/
      const { scrollHeight, scrollTop, clientHeight } = this.$refs.container;
      const entireHeight = Math.round(scrollTop + clientHeight);
      this.loadingScroll = false;
      if (entireHeight >= scrollHeight && !this.loadingScroll) {
        const validationLengthImages = this.validationMoreImages();
        if (!validationLengthImages) return;
        this.addItem();
        this.loadingScroll = true;
      }
    },
    addItem() {
      this.page++;
      this.$store.dispatch("post/images/FIND", {
        take: this.take,
        page: this.page,
      });
    },
    validationMoreImages() {
      if (this.firstAmountOfImages === 0) {
        this.firstAmountOfImages = this.addImages.length;
        return true;
      }
      if (this.firstAmountOfImages < this.addImages.length) {
        this.firstAmountOfImages = this.addImages.length;
        return true;
      } else return false;
    },
    urlThumbnail(images) {
      return images.find((item) => item.quality === "SCREEN_4");
    },
    captureImage($evt, url) {
      let img = new Image();
      img.src = url;
      this.image = img;
    },
    startDrag(evt, image) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setDragImage(this.image, 75, 75);
      const { width, height } = this.setHW(image);
      this.$store.commit("post/drag/DRAG_IMAGE_START", {
        ...image,
        typeItem: "image",
        uuid: uuidv4(),
        width,
        height,
        transform: {},
        filter: {},
        currentValues: {},
        cropped: {
          width,
          height,
          x: 0,
          y: 0,
        },
      });
      this.image = null;
    },
    endDrag() {
      this.$store.commit("post/drag/DRAG_IMAGE_END");
    },
    setHW(image) {
      const imageResize = image.thumbnails.find(
        (item) => item.quality === "SCREEN_3"
      );
      let width;
      let height;
      if (imageResize.width >= 533 && imageResize.height >= 800) {
        width = 420.267;
        height = 630.4;
      } else if (imageResize.width >= 640 && imageResize.height >= 800) {
        width = 504;
        height = 630;
      } else if (imageResize.width >= 800 && imageResize.height >= 532) {
        width = 782;
        height = 522;
      } else {
        width = imageResize.width;
        height = imageResize.height;
      }
      return { width, height };
    },
    startClick(image) {
      const { width, height } = this.setHW(image);

      this.$store.dispatch("post/drag/ADD_IMAGE_IN_CANVAS", {
        ...image,
        uuid: uuidv4(),
        x: this.view.width / 2 - width / 2,
        y: this.view.height / 2 - height / 2,
        width,
        height,
        filter: {},
        currentValues: {},
        rotate: 0,
        cropped: {
          width,
          height,
          x: 0,
          y: 0,
        },
      });
    },
    //* Reset variables to default values when the user leave keyword searching
    resetVariablesScroll() {
      //* this is for scroll mode
      this.addImages = [];
      this.firstAmountOfImages = 0;
      this.page = 1;      
    }
  },
};
</script>

<style scoped lang="scss">
.image-horizontal {
  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
</style>
