<template>
  <v-app>
    <v-main style="padding: 0px">
      <router-view></router-view>
      <no-auth-modal />
      <cp-global-modal-alert />
      <cp-isc-double-btn-modal
        v-model="isShowModalBtn"
        :title="titleModalBtn"
        :paragraph="paragraphModalBtn"
        btnMsg1="No"
        btnMsg2="Yes"
        :showLoader="isShowLoader"
        @send-result-btn1="redirectToLogin"
        @send-result-btn2="setTokenAndSession"
      />
    </v-main>
  </v-app>
</template>
<script>
import {
  NoAuthModal,
  CpGlobalModalAlert,
  DoubleBtnMixin,
  ExpTokenMixin,
  CpIscDoubleBtnModal,
} from "@design/styleguide";

export default {
  components: {
    NoAuthModal,
    CpGlobalModalAlert,
    CpIscDoubleBtnModal,
  },
  mixins: [DoubleBtnMixin, ExpTokenMixin],
};
</script>
<style scoped>
/* #post {
  margin-top: 60px;
} */
</style>
