<template>
  <div class="post_options">
    <v-list-item v-if="!isBlocked">
      <v-tooltip left color="white">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            tile
            v-bind="attrs"
            v-on="on"
            width="40"
            height="40"
            @click="clone()"
          >
            <v-icon size="16">$clone</v-icon>
          </v-btn>
        </template>
        <span class="grey-2--text">Clone</span>
      </v-tooltip>
    </v-list-item>

    <v-list-item v-if="!isBlocked">
      <v-menu
        nudge-left="165"
        content-class="arrow"
        z-index="10000"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
          <v-tooltip left color="white">
            <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
              <v-btn
                tile
                v-bind="{ ...attrsMenu, ...attrsTooltip }"
                v-on="{ ...onMenu, ...onTooltip }"
                icon
                width="40"
                height="40"
              >
                <v-icon size="16">$order</v-icon>
              </v-btn>
            </template>
            <span class="grey-2--text">Order</span>
          </v-tooltip>
        </template>
        <v-card class="mx-auto card-order" max-width="151" width="151">
          <v-list class="arrow-left" dense>
            <v-list-item @click="zindex('bring-forward')">
              <v-list-item-title class="label--input"
                >Bring forward</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="zindex('bring-to-front')">
              <v-list-item-title class="label--input"
                >Bring to front</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="zindex('send-forward')">
              <v-list-item-title class="label--input"
                >Send forward</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="zindex('send-to-back')">
              <v-list-item-title class="label--input"
                >Send to back</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-list-item>

    <v-list-item color="gray" v-if="!isBlocked">
      <v-menu
        nudge-left="230"
        content-class="arrow"
        z-index="10000"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
          <v-tooltip left color="white">
            <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
              <v-btn
                tile
                v-bind="{ ...attrsMenu, ...attrsTooltip }"
                v-on="{ ...onMenu, ...onTooltip }"
                icon
                width="40"
                height="40"
                @click="initTransparency()"
              >
                <v-icon size="16">$transparency</v-icon>
              </v-btn>
            </template>
            <span class="grey-2--text">Transparency</span>
          </v-tooltip>
        </template>
        <v-card class="mx-auto" max-width="215" width="215">
          <v-list class="arrow-left">
            <v-list-item class="py-2">
              <div style="width: 100%">
                <label class="label--input d-block pt-2">Transparency</label>

                <div class="d-flex align-center">
                  <isc-slider
                    v-model="transparency"
                    @end="endTransparency"
                    :min="'0'"
                    :max="'100'"
                  ></isc-slider>
                  <span class="label--input ms-3">{{ transparency }} %</span>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-list-item>
    <v-list-item @click="block()">
      <v-tooltip left color="white">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon tile v-bind="attrs" v-on="on" width="40" height="40">
            <v-icon size="16">$block</v-icon>
          </v-btn>
        </template>
        <span class="grey-2--text">Block</span>
      </v-tooltip>
    </v-list-item>
    <v-list-item @click="trash()" v-if="!isBlocked">
      <v-tooltip left color="white">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon tile v-bind="attrs" v-on="on" width="40" height="40">
            <v-icon size="16">$trash</v-icon>
          </v-btn>
        </template>
        <span class="grey-2--text">Delete</span>
      </v-tooltip>
    </v-list-item>
  </div>
</template>

<script>
/* eslint-disable no-debugger */
import SliderInput from "@/components/inputs/SliderInput";
import IscSlider from "@/components/inputs/IscSlider";

export default {
  name: "SideControls",
  components: { SliderInput, IscSlider },
  data() {
    return {
      show: true,
      transparency: 100,
    };
  },
  computed: {
    selected() {
      return this.$store.getters["post/image/selected"];
    },
    isBlocked() {
      return this.$store.getters["post/sideControls/isBlocked"];
    },
    images() {
      return this.$store.getters['post/drag/images'];
    },
  },
  watch:{
    selected(n,o){
      this.initTransparency();
    }
  },
  methods: {
    clone() {
      this.$store.dispatch("post/sideControls/CLONE_ITEM", {
        uuid: this.selected,
      });
      // this.$store.dispatch('post/SAVE_CHANGES');
      this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
    },
    zindex(index) {
      this.$store.dispatch("post/sideControls/MOVE_ITEM", {
        uuid: this.selected,
        index,
      });
      // this.$store.dispatch('post/SAVE_CHANGES');
      this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
    },
    block() {
      this.$store.dispatch("post/sideControls/BLOCK_ITEM", {
        uuid: this.selected,
      });
      // this.$store.dispatch('post/SAVE_CHANGES');
      this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
    },
    trash() {
      this.$store.dispatch("post/sideControls/DELETE_ITEM", {
        uuid: this.selected,
      });
      this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
    },
    endTransparency() {
      this.$store.dispatch("post/rect/transparencyItem", {
        uuid: this.selected,
        transparency: this.transparency / 100,
      });
      // this.$store.dispatch('post/SAVE_CHANGES');
      this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
    },
    getItemByUuid(uuid){
      return this.images.find((e)=> e.uuid == uuid)
    },
    initTransparency(){
      let itemTransparency = this.getItemByUuid(this.selected).transparency;
      this.transparency = itemTransparency * 100;
    }
  },
  created(){
    this.initTransparency()
  }
};
</script>

<style scoped lang="scss">
::v-deep.v-tooltip__content {
  border-radius: 3px;
  background-color: #fff;
  padding: 8px 10px 8px 8px;
  line-height: 10px;
  font-size: 10px;
  font-weight: bold;
  box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
  margin-right: 20px;

  &::after {
    content: "";
    box-shadow: 0px 2px 6px 2px rgba(44, 56, 82, 0.1);
    position: absolute;
    background-color: #fff;
    width: 8px;
    height: 10px;
    transform: translateY(-50%);
    top: 50%;
    right: -7px;
    clip-path: polygon(0 0, 0 100%, 100% 50%);
  }
  & .grey-2--text {
    color: #5d6b88;
  }
}
.post_options {
  & > .v-list-item {
    margin-bottom: 2px;
    background-color: #e9eaee;
    border-radius: 3px 0 0 3px;
    width: 40px;
    min-height: 40px;
    height: 40px;
    padding: 0;
  }
}
::v-deep.theme--light.v-btn:hover:before {
  background: #5d6b88;
  opacity: 1;
}
::v-deep.theme--light.v-btn:hover {
  span.v-icon svg > path {
    fill: #ffffff;
  }
}
.arrow {
  box-shadow: none;
  padding-right: 22px;
}
::v-deep.v-menu__content .arrow-left {
  &:after {
    content: "";
    position: absolute;
    height: 22px;
    width: 28px;
    left: 100%;
    top: 7px;
    border: 15px solid transparent;
    border-left: 18px solid white;
  }
}
::v-deep.v-menu__content {
  border: none !important;
  box-shadow: 0px 0px 0px 0px rgb(44 56 82 / 10%) !important;
}
</style>
