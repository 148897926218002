<template>
  <div v-if="!isSavedPosts" class="loader-container">
    <div class="loader-container__progress">
      <div class="loader-container__progress--text">
        Sorry, we couldn't find any saved items
      </div>
    </div>
  </div>
  <div v-else-if="addSavedPosts.length === 0">
    <div class="loader-container">
      <div class="loader-container__progress">
        <div class="loader-container__progress--text">
          Getting your Saved Posts
        </div>
        <v-progress-linear
          color="white"
          height="6"
          indeterminate
          rounded
        ></v-progress-linear>
      </div>
    </div>
  </div>
  <div
    v-else
    class="wrapper-scroll scroll-base container_image_scroll"
    v-on:scroll="handleScroll"
    ref="container"
  >
    <div
      class="img_wrapper"
      v-for="savePost in addSavedPosts"
      :key="savePost.uuid"
      @click="chooseSaved(savePost)"
    >
      <v-img
        :src="savePost.finalImage"
        :alt="savePost.name"
        aspect-ratio="1"
        style="margin: 4px; cursor: pointer"
      />
    </div>
  </div>
</template>
<script>
const idClientStorage = sessionStorage.getItem("client_id");
const clientId = Number(idClientStorage || process.env.VUE_APP_ID);
export default {
  components: {},
  name: "PostSaved",
  data() {
    return {
      take: 12,
      page: 1,
      addSavedPosts: [],
      loadingScroll: false,
      firstAmountOfSaved: 0,
      isSavedPosts: false,
      //activeScroll: false,
    };
  },
  created() {
    console.log(" created ");
    this.$store.dispatch("post/saved/FIND", {
      take: this.take,
      page: this.page,
      clientId,
    });
    this.$store.commit("post/saved/SET_ACTIVE", true);
  },
  computed: {
    savePosts() {
      return this.$store.getters["post/saved/savedAll"];
    },
    cleanPosts() {
      return this.$store.getters["post/saved/cleanSavedPost"];
    },
  },
  watch: {
    savePosts(savePosts) {
      if (savePosts.length > 0) {
        this.isSavedPosts = true;
        savePosts.forEach((el) => {
          this.addSavedPosts.push(el);
        });
      }
    },
    /* trigger(value) {
      if (value) this.activeScroll = true;
      else this.activeScroll = false;
      this.addSavedPosts = [];
      this.firstAmountOfSaved = 0;
      this.page = 1;
    }, */
    cleanPosts(val) {
      if (val) this.addSavedPosts = [];
    },
  },
  methods: {
    handleScroll() {
      /* 
				scrollHeight: all height scroll
				scrollTop: height location where scroll is marked. 
				style.height: height of view where scroll works.
			*/
      const { scrollHeight, scrollTop, clientHeight } = this.$refs.container;
      const entireHeight = scrollTop + clientHeight;
      this.loadingScroll = false;

      if (entireHeight >= scrollHeight && !this.loadingScroll) {
        const validationLengthSaved = this.validationMoreSaved();
        if (!validationLengthSaved) return;
        this.addItem();
        this.loadingScroll = true;
      }
    },
    addItem() {
      this.page++;
      this.$store.dispatch("post/saved/FIND", {
        take: this.take,
        page: this.page,
        clientId,
      });
    },
    validationMoreSaved() {
      if (this.firstAmountOfSaved === 0) {
        this.firstAmountOfSaved = this.addSavedPosts.length;
        return true;
      }
      if (this.firstAmountOfSaved < this.addSavedPosts.length) {
        this.firstAmountOfSaved = this.addSavedPosts.length;
        return true;
      } else return false;
    },
    chooseSaved(savePost) {
      this.$store.dispatch("post/main/LOAD_POST_CANVAS", savePost);
    },
  },
  destroyed() {
    this.$store.commit("post/saved/SET_CLEAN_POSTS", false);
    this.$store.commit("post/saved/SET_ACTIVE", false);
  },
};
</script>

<style lang="scss" scoped>
.img_wrapper {
  width: 154px;
  height: 154px;
  display: inline-block;
  overflow: hidden;
  img {
    margin: 4px;
    cursor: pointer;
  }
}
.margin-1rem {
  margin: 1rem 0.6rem;
}
</style>
