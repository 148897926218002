<template>
  <v-menu
    offset-y
    origin="center"
    nudge-top="-5"
    nudge-left="135"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on" small> Adjust</v-btn>
    </template>
    <v-list outlined>
      <v-list-item v-for="(item, index) in items" :key="index">
        <v-list-item-content>
          <v-row align-md="center">
            <v-col md="3" class="py-0">
              <p class="v-list-item__paragraph">{{ item.title }}</p>
            </v-col>
            <v-col md="7">
              <isc-slider
                v-model="item.percent"
                @input="changeFilter"
                @end="endFilter"
                :min="item.min"
                :max="item.max"
                :value="item.percent"
              ></isc-slider>
              {{ item.convert }}
            </v-col>
            <v-col md="2" class="py-1 pl-0">
              <p class="v-list-item__paragraph">{{ item.toRound }}%</p>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import IscSlider from "@/components/inputs/IscSlider";

export default {
  name: "AdjustCanvas",
  components: { IscSlider },
  computed: {
    selected() {
      return this.$store.getters["post/image/selected"];
    },
    selectedDetail() {
      return this.$store.getters["post/image/selectedDetail"];
    },
    images() {
      return this.$store.getters["post/drag/images"];
    },
    items() {
      const blur = this.selectedDetail.currentValues?.adjust?.blur * 100 || 0;
      const brightness = this.selectedDetail.currentValues?.adjust?.brightness
        ? this.selectedDetail.currentValues.adjust.brightness * 100
        : 100;
      const contrast = this.selectedDetail.currentValues?.adjust?.contrast
        ? this.selectedDetail.currentValues.adjust.contrast * 100
        : 100;
      const saturate = this.selectedDetail.currentValues?.adjust?.saturate
        ? this.selectedDetail.currentValues.adjust.saturate * 100
        : 100;
      return [
        {
          title: "Brightness",
          filter: "brightness",
          min: 0,
          max: 200,
          percent: brightness,
          toRound: Math.round(brightness - 100),
        },
        {
          title: "Contrast",
          filter: "contrast",
          min: 0,
          max: 200,
          percent: contrast,
          toRound: Math.round(contrast - 100),
        },
        {
          title: "Saturation",
          filter: "saturate",
          min: 0,
          max: 200,
          percent: saturate,
          toRound: Math.round(saturate - 100),
        },
        {
          title: "Blur",
          filter: "blur",
          min: 0,
          max: 100,
          percent: blur,
          toRound: Math.round(blur),
        },
      ];
    },
  },
  methods: {
    changeFilter() {
      this.$store.dispatch("post/navControls/CHANGE_ADJUST", {
        uuid: this.selected,
        filters: this.items,
      });

      this.$store.dispatch("post/image/UPDATE_IMAGE_SELECTED", this.selected);
    },
    endFilter() {
      // this.$store.dispatch('post/SAVE_CHANGES');
      this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
    },
  },
};
</script>

<style scoped lang="scss">
.v-menu__content {
  box-shadow: none;
  padding-top: 22px;
}
.v-list {
  border-radius: 6px;
  padding: 8px 5px;
  width: 330px;
  position: relative;
  border: none;
  &::before {
    content: "";
    position: absolute;
    height: 22px;
    width: 28px;
    background: #fff;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    left: 50%;
    transform: translateX(-50%);
    top: -20px;
  }
}
.v-list-item {
  min-height: 35px;
}

.v-list-item__content {
  padding: 0;
}
.v-list-item__paragraph {
  margin: 0;
  color: #5d6b88;
  font-family: Oxygen;
  margin-top: 3px;
}
::v-deep.v-menu__content {
  border: none !important;
  box-shadow: 0px 0px 0px 0px rgb(44 56 82 / 10%) !important;
}
</style>