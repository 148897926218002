<template>
  <div
    class="image-horizontal__content wrapper-scroll scroll-base container_image_scroll"
    v-on:scroll="handleScroll"
    ref="container"
  >
    <div class="horizontal-masonry horizontal-masonry--h">
      <figure
        v-for="(item, i) in images.items"
        :key="i"
        class="horizontal-masonry-brick horizontal-masonry-brick--h image-item"
      >
        <v-img
          class="horizontal-masonry-img"
          :src="urlThumbnail(item.thumbnails).location"
          alt="item.name"
          @click="startClick(item)"
          @pointerdown="
            captureImage($event, urlThumbnail(item.thumbnails).location)
          "
          draggable
          @dragstart="startDrag($event, item)"
          @dragend="endDrag"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </figure>
      <template v-if="isLoading">
        <v-skeleton-loader
          class="horizontal-masonry-brick horizontal-masonry-brick--h"
          type="image"
          v-for="i in take"
          :key="i"
          dark
          tile
        ></v-skeleton-loader>
      </template>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from '@design/styleguide';
export default {
  name: "PostImages",
  data: () => ({
    image: null,
    take: 10,
    page: 0,
    addImages: [],
    loadingScroll: false,
    firstAmountOfImages: 0,
    activeScroll: true,
    showImages: true,
    base_url_images_thumb:
      process.env.BASE_URL_IMAGES_THUMBS ||
      "https://d2wk4yjqh165z7.cloudfront.net/fit-in/0x154/img",
    currentImages: []
  }),
  props: {
    trigger: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isLoading: 'post/images/isLoading',
      view: 'post/zoom/post',
      imagesByKeywords: 'post/images/imagesByKeywords',
      defaultImages: 'post/images/images'
    }),
    images() {
      return {
        scrollable: this.defaultImages.length ? true : false,
        items: this.showImages ? this.currentImages : this.imagesByKeywords,
        hasMore: this.defaultImages.length ? true : false
      }
    }
  },
  watch: {
    trigger(value) {
      if (value) this.showImages = true;
      else {
        this.$store.commit("post/images/RESET_IMAGES_BY_KEYWORDS");
        this.showImages = false;
        this.resetVariablesScroll();
      }
    },
  },
  mounted() {
    this.loadMoreItems()
  },
  methods: {
    handleScroll() {
      const container = this.$refs.container;
      const lastChild = container.querySelector('.image-item:last-of-type')

      if(!lastChild) return

      const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                  this.loadMoreItems();
              }
            });
          },
          {
            root: null,
            threshold: 0.9, 
          }
        );
        observer.observe(lastChild);
    },
    async loadMoreItems() {
      if(!this.isLoading && this.images.hasMore && this.showImages || this.page === 0) {  

        this.page++;
        await this.$store.dispatch("post/images/FIND", {
          take: this.take,
          page: this.page,
        });

        this.currentImages.push(...this.defaultImages)

        const container = this.$refs.container;
        
        if(!container) return

        if(container.scrollHeight <= container.clientHeight) this.loadMoreItems()
      }
    },
    validationMoreImages() {
      if (this.firstAmountOfImages === 0) {
        this.firstAmountOfImages = this.addImages.length;
        return true;
      }
      if (this.firstAmountOfImages < this.addImages.length) {
        this.firstAmountOfImages = this.addImages.length;
        return true;
      } else return false;
    },
    urlThumbnail(images) {
      return images.find((item) => item.quality === "SCREEN_4");
    },
    captureImage($evt, url) {
      let img = new Image();
      img.src = url;
      this.image = img;
    },
    startDrag(evt, image) {

      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setDragImage(this.image, 75, 75);
      const { width, height } = this.setHW(image);
      this.$store.commit("post/drag/DRAG_IMAGE_START", {
        ...image,
        typeItem: "image",
        uuid: uuidv4(),
        width,
        height,
        transform: {},
        filter: {},
        currentValues: {},
        cropped: {
          width,
          height,
          x: 0,
          y: 0,
        },
      });
      this.image = null;
    },
    endDrag() {
      this.$store.commit("post/drag/DRAG_IMAGE_END");
    },
    setHW(image) {
      const imageResize = image.thumbnails.find(
        (item) => item.quality === "SCREEN_3"
      );
      let width;
      let height;
      if (imageResize.width >= 533 && imageResize.height >= 800) {
        width = 420.267;
        height = 630.4;
      } else if (imageResize.width >= 640 && imageResize.height >= 800) {
        width = 504;
        height = 630;
      } else if (imageResize.width >= 800 && imageResize.height >= 532) {
        width = 782;
        height = 522;
      } else {
        width = imageResize.width;
        height = imageResize.height;
      }
      return { width, height };
    },
    startClick(image) { 
      const { width, height } = this.setHW(image);

      this.$store.dispatch("post/drag/ADD_IMAGE_IN_CANVAS", {
        ...image,
        uuid: uuidv4(),
        x: this.view.width / 2 - width / 2,
        y: this.view.height / 2 - height / 2,
        width,
        height,
        filter: {},
        currentValues: {},
        rotate: 0,
        cropped: {
          width,
          height,
          x: 0,
          y: 0,
        },
      });
    },
    //* Reset variables to default values when the user leave keyword searching
    resetVariablesScroll() {
      //* this is for scroll mode
      this.addImages = [];
      this.firstAmountOfImages = 0;
      // this.page = 1;      
    }
  },
};
</script>

<style scoped lang="scss">
.image-horizontal {
  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
  }
}
</style>
