import LienzoImage from "./lienzo/LienzoImage.vue";
import ImageCropper from '@/components/cropper/ImageCropper';
import ContentResize from './resize/ContentResize.vue';
import ContentCrop from './crop/ContentCrop.vue';
import ContentBlocked from './blocked/ContentBlocked';
import ContentGuideLines from './guide-lines/ContentGuideLines.vue';
import TextEditInput from '@/components/inputs/TextEditInput.vue'

export default {
  name: "ContentModule",
  components: { LienzoImage, ImageCropper, ContentResize, ContentCrop, ContentBlocked, TextEditInput, ContentGuideLines },
  computed: {
    background() {
      return this.$store.getters["post/background/background"]
    },
    zoom() {
      return this.$store.getters['post/zoom/zoom'];
    },
    size() {
      return this.$store.getters['post/zoom/post'];
    },
    draggingObject() {
      return this.$store.getters['post/drag/image'];
    },
    allItems() {
      const images = this.$store.getters['post/drag/images'];
      return images;
    },
    images() {
      const images = this.$store.getters['post/drag/images'];
      return images.filter(({ typeItem }) => typeItem != 'text')
    },
    //* filter text  from images array
    textsEdit() {
      const images = this.$store.getters['post/drag/images'];
      return images.filter(({ typeItem }) => typeItem == 'text')
    },
    selectedStylesCut() {
      return this.$store.getters['post/drag/selectedStylesCut'];
    },
    selected() {
      return this.$store.getters['post/image/selected'];
    },
    selectedDetail() {
      return this.$store.getters['post/image/selectedDetail'];
    },
    isCrop() {
      return this.$store.getters['post/navControls/isCrop']
    },
    isCropSaved() {
      return this.$store.getters['post/navControls/isCropSaved']
    },
    selectedStylesCropCut() {
      return this.$store.getters['post/navControls/selectedStylesCrop']
    },
    isBlocked() {
      return this.$store.getters['post/sideControls/isBlocked']
    },
    texts() {
      return this.$store.getters['post/drag/texts']
    },
    quillEditor() {
      return this.$store.getters['post/drag/quillEditor']
    },
    isTextSelected() {
      return this.$store.getters['post/canvas/isTextSelected']
    },
    imagesSeletedByBox() {
      return this.$store.getters["post/drag/imagesSeletedByBox"];
    },
    isGuideLinesActive() {
      return this.$store.getters["post/guideLines/isActive"];
    },
    isHoverStyles ()  {
      return this.$store.getters["post/drag/hoverStyles"];
    },
    isActiveToTextEdit() {
      return this.$store.getters["post/drag/isActiveToTextEdit"];
    },
    isDragging() {
      return this.$store.getters["post/drag/isDragging"];
    },
  },
  data: () => ({
    isHover: false,
    isClicked: false,
    isMoved: false,
    isResizing: false,
    selectedStyles: null,
    mouseX: 0,
    mouseY: 0,
    translateX: 0,
    translateY: 0,
    originalX: 0,
    originalY: 0,
    originalW: 0,
    originalH: 0,
    rotate: 0,
    minimun: 100,
    resizeType: null,
    centers: null,
    elementSelected: null,
    selectedStylesCrop: null,
    crop: {
      minimun: 100,
      resizeType: null,
      rotate: 0,
      mouse: {
        x: 0,
        y: 0
      },
      original: {
        w: 0,
        h: 0,
        x: 0,
        y: 0
      },
      translate: {
        x: 0,
        y: 0
      }
    },
    dragging: false,
    draggingMove: false,
    begin: { x: 0, y: 0 },
    end: { x: 0, y: 0 },
    width: 0,
    height: 0,
    top: 0,
    left: 0,
    beginMove: { x: 0, y: 0 },
    topMove: 0,
    leftMove: 0,
    elementsToMove: [],
    prevElementsToMove: [],
    itemToCopy: null,
    isShowToolbar: false,
    isTextEdit: true,
    cursorX: 0,
    calculatedPointerX: 0,
    cursorY: 0,
    calculatedPointerY: 0,
  }),
  created() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    setHoverStyles(item){
      if(!this.isDragging){
        // if(!item.isEditing) this.$store.dispatch('post/drag/UPDATE_HOVER_STYLES_SELECTED', null);
        if(!item.isEditing && item.typeItem == 'text' || item.typeItem == 'image' || item.typeItem == 'shape'){

          // console.log('setHoverStyles',item);
          // if (item.typeItem != 'text') this.$store.dispatch('post/canvas/IS_TEXT_SELECTED', false)
    
          this.$store.dispatch('post/drag/UPDATE_HOVER_STYLES_SELECTED', null);
    
          if(item.uuid != this.selectedImage) {
            this.$store.dispatch('post/drag/UPDATE_HOVER_STYLES_SELECTED', {
              width: item.width,
              height: item.height,
              transform: {
                translate: {
                  x: item.x,
                  y: item.y,
                },
                rotate: item.rotate
              },
              uuid: item.uuid
            })
          }
    
          this.$store.dispatch('post/drag/UPDATE_HOVER', false);
        }
      }
    },
    /**
     ** Get mouse position coordinates
     * 
     * @param {*} event
     */
    handleMouseMove(event){
      const container = this.$refs.capture;
      const containerRect = container.getBoundingClientRect();

      // Obtén las coordenadas relativas al contenedor
      this.cursorX = (event.clientX - containerRect.left);
      this.cursorY = (event.clientY - containerRect.top);
      this.calculatedPointerX = (this.cursorX / this.zoom.transform);
      this.calculatedPointerY = (this.cursorY / this.zoom.transform);
      // console.log('Mouse Y:----', mouseY);
    },
    emitRefCapture() {
      this.$emit('capture', this.$refs.capture);
    },
    //* it only works for images and shapes
    onDrop(evt) {
      //console.log('draggin ', this.draggingObject);
      const x = (evt.layerX);
      const y = (evt.layerY);
      // this.$store.commit('post/drag/ADD_DRAG_IMAGE', {
      //   ...this.dragging,
      //   x,
      //   y,
      //   rotate: 0,
      //   zIndex: 0,
      //   blocked: false
      // })
      if (this.draggingObject.typeItem === 'image') {
        this.$store.dispatch('post/drag/ADD_IMAGE_IN_CANVAS', {
          ...this.draggingObject,
          x,
          y,
          rotate: 0,
          zIndex: 0,
          blocked: false
        })
      } else if (this.draggingObject.typeItem === 'shape') {
        this.$store.dispatch('post/drag/ADD_SHAPE_IN_CANVAS', {
          ...this.draggingObject,
          x,
          y,
          rotate: 0,
          zIndex: 0,
          blocked: false
        })
      }else if (this.draggingObject.typeItem === 'text') {
        let text = this.draggingObject.content[0];
          text.height = this.draggingObject.height;
          text.width = this.draggingObject.width;
          text.x = x;
          text.y = y;
          text.rotate = 0;
          this.$store.dispatch("post/drag/ADD_TEXT_IN_CANVAS", text);
          // console.log('draggin ', text,x,y);
      }
    },
    onHover(item) {
      if (item && item.focus && !item.clicked) {
        this.selectedStyles = {
          transform: `translate(${item.x}px, ${item.y}px) rotate(${item.rotate}deg)`,
          height: `${item.height}px`,
          width: `${item.width}px`,
        };
      } else {
        this.selectedStyles = null
      }
    },
    clickText(item) {
      //console.log('CLICK_TEXT ==> ', item);
      this.$store.dispatch('post/image/SELECT_IMAGE_IN_CANVAS', null);
      this.$store.dispatch('post/image/SELECT_IMAGE_IN_CANVAS', item.uuid);
      this.$store.dispatch('post/drag/UPDATE_STYLES_IN_SELECTION_RESIZE', {
        width: item.width,
        height: item.height,
        transform: {
          translate: {
            x: item.x,
            y: item.y
          },
          rotate:item.rotate
        }
      })
      this.$store.dispatch('post/drag/UPDATE_ROTATE', item.rotate)
    },
    /**
     ** Reset for many actions when the user clicks outside "Lienzo" calling "Box" and inside
     * @param {*} item
     */
    onClicked(item) {
      // console.log('ON_CLIKED ==>  ', item);
      if(item == null) this.$store.dispatch('post/drag/DISABLED_ALL_EDITING_TEXT');
      //* if the document.activeElement is an instance of the HTMLElement class. 
      //* If it is, it calls the blur() method on the active element, which removes focus from it.
      if (document.activeElement instanceof HTMLElement){
        document.activeElement.blur();
      }

      if (item === 'lienzo' || item === 'box') {
        // this.$store.dispatch('post/drag/REMOVE_STYLES_IN_SELECTION_RESIZE');
        this.$store.dispatch('post/drag/UPDATE_HOVER_STYLES_SELECTED', null);
        this.$store.dispatch('post/drag/DISABLED_ALL_EDITING_TEXT');
        this.$store.commit('post/image/SET_SELECTED_IMAGE', null);
        this.$store.dispatch('post/image/UPDATE_IMAGE_SELECTED', null)
        this.$store.dispatch('post/canvas/IS_TEXT_SELECTED', false)
        
        // this.$store.dispatch('post/drag/UPDATE_STYLES_IN_SELECTION_RESIZE', null)
        this.$store.dispatch("post/navControls/CROP", false);
        this.isClicked = false;
        if (this.quillEditor != null) this.$store.dispatch('post/drag/DESTROY_QUILL');
      } else if (item) {
        if (item.typeItem != 'text') this.$store.dispatch('post/canvas/IS_TEXT_SELECTED', false)
        this.$store.dispatch("post/drag/UPDATE_STYLES_IN_SELECTION_RESIZE", {
          width: item.width,
          height: item.height,
          transform: {
            translate: {
              x: item.x,
              y: item.y,
            },
            rotate:item.rotate
          },
        });
        this.$store.dispatch('post/image/UPDATE_IMAGE_SELECTED', item.uuid)
        this.isClicked = true;
      } else {
        //* this is when item is null
        this.$store.dispatch("post/navControls/CROP", false);
        this.$store.dispatch('post/image/UPDATE_IMAGE_SELECTED', null)
        this.$store.commit('post/image/SET_SELECTED_IMAGE', null);
        this.$store.commit('post/drag/SET_STYLES_SELECTED', null);
        // this.$store.commit('post/drag/REMOVE_STYLES_SELECTED');
        this.$store.dispatch('post/canvas/IS_TEXT_SELECTED', false)
        this.isClicked = false;
        this.width = 0
        this.height = 0
        this.$store.commit('post/drag/UPDATE_IMAGES_SELECTED_BOX', []);
      }
    },
    clicked(item) {
      if (!item.blocked && this.isBlocked) {
        this.onClicked(item)
        return
      }
      if (item.blocked) {
        this.onClicked(item)
        return
      }
      if (this.isClicked && !item.move && !this.isBlocked) {
        this.selectedStyles = null
        this.translateX = item.x;
        this.translateY = item.y;
        
        //console.log('clicked', item);
        this.$store.commit('post/drag/SET_STYLES_SELECTED', {
          transform: `translate(${item.x}px, ${item.y}px) rotate(${item.rotate}deg)`,
          height: `${item.height}px`,
          width: `${item.width}px`,
        })
        this.$store.dispatch('post/navControls/CROP_RESIZE', {
          transform: `translate(0px, 0px) rotate(${item.rotate}deg)`,
          height: `${item.height}px`,
          width: `${item.width}px`,
        })
        this.isMoved = false;
      } else {
        this.isMoved = true;
        this.selectedStyles = {
          transform: `translate(${item.x}px, ${item.y}px) rotate(${item.rotate}deg)`,
          height: `${item.height}px`,
          width: `${item.width}px`,
        };
        this.translateX = 0;
        this.translateY = 0;
      }

    },
    rotation($event) {
      $event.preventDefault()
      window.addEventListener('pointermove', this.rotationMove);
      window.addEventListener('pointerup', this.rotationEnd);
    },
    rotationMove($event) {
      const element = this.$refs.resizeOut;
      const offset = element.getBoundingClientRect();
      this.originalW = parseFloat(this.selectedStylesCut.width.replace('px', ''));
      this.originalH = parseFloat(this.selectedStylesCut.height.replace('px', ''));
      const center_x = (offset.left) + (this.originalW / 2);
      const center_y = (offset.top) + (this.originalH / 2);
      const mouse_x = $event.pageX;
      const mouse_y = $event.pageY;
      const radians = Math.atan2(mouse_x - center_x, mouse_y - center_y);
      const degree = radians * (180 / Math.PI) * -1;


      if (this.rotate !== degree) {
        this.rotate = degree;

        this.$store.dispatch('post/drag/EVERY_ROTATE', {
          uuid: this.selected,
          rotate: this.rotate,
        });

        this.$store.commit('post/drag/SET_STYLES_SELECTED', {
          transform: `translate(${this.translateX}px, ${this.translateY}px) rotate(${this.rotate}deg)`,
          height: `${this.originalH}px`,
          width: `${this.originalW}px`,
        })
      }
    },
    rotationEnd($event) {
      this.$store.dispatch('post/drag/ROTATE', {
        uuid: this.selected,
        rotate: this.rotate
      });

      window.removeEventListener('pointermove', this.rotationMove);
      window.removeEventListener('pointerup', this.rotationEnd);
    },
    deleteItem() {
      if (this.isTextSelected) return;
      this.$store.dispatch("post/sideControls/DELETE_ITEM", { uuid: this.selected });
    },
    //****** POINTERS FUNCTIONS **********/
    /**
     ** Set the values width, left, height and top, when the mouse is moving.
     *
     * @param {*} event
     * @return {*} 
     */
    pointermove(event) {
      if (!this.dragging) return;
      this.end = {
        x: event.x,
        y: event.y,
      }
      if (this.end.x > this.begin.x) {
        this.width = (event.x - this.begin.x) * (1 / this.zoom.transform);
      } else {
        this.width = (this.begin.x - event.x) * (1 / this.zoom.transform);
        this.left = event.offsetX
      }

      if (this.end.y > this.begin.y) {
        this.height = (event.y - this.begin.y) * (1 / this.zoom.transform);
      } else {
        this.height = (this.begin.y - event.y) * (1 / this.zoom.transform);
        this.top = event.offsetY
      }
    },
    // * does not work
    /* pointerdown(event) {
      const target = event.target;
      event.preventDefault();
      event.stopPropagation();
      target.setPointerCapture(event.pointerId);
      this.dragging = true;
      this.begin = {
        x: event.x,
        y: event.y
      }
      this.width = 0
      this.height = 0
      this.left = event.offsetX
      this.top = event.offsetY
    }, */

    /**
     * Handles the pointerup event.
     *
     * @param {Event} event - The pointerup event object.
     * @return {void} This function does not return a value.
     */
    pointerup(event) {
      this.pointermove(event);
      if (this.dragging) {
        this.$store.dispatch("post/drag/CAPTURE_IMAGES_IN_SELECT_BOX", { width: this.width, height: this.height, left: this.left, top: this.top })
        const el = document.getElementById('box_select')
        el.removeEventListener('pointerup', this.pointerup);
        el.removeEventListener('pointermove', this.pointermove);
      }
      this.dragging = false;
    },
    //* they do not work (Function) both
   /*  pointermoveMove(event) {
      if (!this.draggingMove) return;
      this.leftMove = this.left + ((event.x - this.beginMove.x) * (1 / this.zoom.transform))
      this.topMove = this.top + ((event.y - this.beginMove.y) * (1 / this.zoom.transform))
      for (const el of this.elementsToMove) {
        const prevEl = this.prevElementsToMove.find(({ uuid }) => uuid == el.uuid)
        this.$store.dispatch("post/drag/TRANSLATE_SELECTED", {
          uuid: el.uuid,
          x: prevEl.x + ((event.x - this.beginMove.x) * (1 / this.zoom.transform)),
          y: prevEl.y + ((event.y - this.beginMove.y) * (1 / this.zoom.transform))

        });
      }
    }, */
    /* pointerdownMove(event) {
      const target = event.target;
      event.preventDefault();
      event.stopPropagation();
      target.setPointerCapture(event.pointerId);
      this.beginMove = {
        x: event.x,
        y: event.y
      }
      this.topMove = this.top
      this.leftMove = this.left
      const filters = this.textsEdit.filter(({ uuid }) => this.imagesSeletedByBox.includes(uuid))
      const filterImage = this.images.filter(({ uuid }) => this.imagesSeletedByBox.includes(uuid))
      this.elementsToMove = [...filters.slice(), ...filterImage.slice()]
      this.prevElementsToMove = [...filters.slice(), ...filterImage.slice()]
      this.draggingMove = true;
    }, */
    
    pointerupMove(event) {
      this.top = this.topMove
      this.left = this.leftMove
      this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
      this.draggingMove = false;
    },
    /**
     ** Handles the click in the Lienzo and gives values such as width, height, left and top.
     ** also handles the pointermove and pointerup to reset the values, 
     * Events: pointerdown (It is a triggered when a pointing device button is pressed down with an element.)
     * @param {MouseEvent} event - The click event.
     */
    clickToSelect(event) {
      //console.log("Click-to-select ", event);
      const target = event.target;
      event.preventDefault();
      event.stopPropagation();
      target.setPointerCapture(event.pointerId);
      this.dragging = true;
      this.begin = {
        x: event.x,
        y: event.y
      }
      this.width = 0
      this.height = 0
      this.left = event.offsetX
      this.top = event.offsetY
      const el = document.getElementById('box_select')
      el.addEventListener('pointerup', this.pointerup);
      el.addEventListener('pointermove', this.pointermove);
    },
    handleKeyDown(e) {

      if (e.keyCode === 67 && e.ctrlKey) { // COPIAR
        if(!this.isActiveToTextEdit){
          this.itemToCopy = null;
          if(this.selected) {
            this.itemToCopy = this.selected;
          }
        }
      }
      
      if (e.keyCode === 86 && e.ctrlKey) { // PEGAR
        if(!this.isActiveToTextEdit){
          if(this.itemToCopy) {
            this.$store.dispatch("post/sideControls/CLONE_ITEM", {
              uuid: this.itemToCopy,
            });
          }
        }
        this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
      }

      if (e.keyCode === 46) { // SUPRIMIR
        if(this.selected) {
          if(!this.isActiveToTextEdit){
            this.$store.dispatch("post/sideControls/DELETE_ITEM", {
              uuid: this.selected,
            });
          }
          this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
        }
      }
    }

  },
  watch: {
    calculatedPointerX(n,o){
      let listItems = this.allItems.filter((el,index)=>{
        let el_x = Math.round(el.x - 6);
        let el_y = Math.round(el.y - 6);
        let endX = Math.round(el.x + el.width) + 6;
        let endY = Math.round(el.y + el.height) + 6;
        if(n >= el_x && n < endX && this.calculatedPointerY >= el_y && this.calculatedPointerY < endY) return el;
        // if(el_x + 1 == n || el_x endX)
      })
      //console.log('Lista de items ',listItems);
      if(listItems.length != 0){
        let lastItem = listItems.at(-1);
        if(lastItem.isEditing) this.$store.dispatch('post/drag/UPDATE_HOVER_STYLES_SELECTED', null);
        // console.log('Item agregado',lastItem);
        this.setHoverStyles(lastItem);
      }
    },
    zoom() {
      const element = this.$refs.mainBox;
      //console.log(element.scrollHeight, element.offsetHeight, element.clientHeight, 'DETAIL');
      element.scrollTop = (element.scrollHeight - element.clientHeight) / 2//element.scrollHeight / 4
    }
  }
}
