<template>
  <span>

      <template v-if="!isCrop">
        <v-btn
          text
          @click="cropImage"
          small
        >
          Crop
        </v-btn>

      </template>
      <template v-else>
        <v-btn
          text
          @click="saveCrop"
        >Save changes</v-btn>
        <v-btn
          text
          @click="cancelCrop"
        >Cancel</v-btn>

      </template>
      </span>
</template>

<script>
export default {
  name: "CropCanvas",
  data: () => ({
    
  }),
  computed: {
    selected() {
      return this.$store.getters["post/image/selected"];
    },
    images() {
      return this.$store.getters['post/drag/images'];
    },
    imageSelected() {
        return this.$store.getters['post/image/selectedDetail'];
    },
    isCrop() {
      return this.$store.getters['post/navControls/isCrop']
    },
    isCropSaved() {
      return this.$store.getters['post/navControls/isCropSaved']
    },
    selectedCrop() {
      return this.$store.getters['post/drag/selectedCrop']
    }
  },
  methods: {
    cropImage() {
      this.$store.dispatch("post/navControls/CROP", true);
    },
    saveCrop() {
      this.$store.dispatch('post/drag/UPDATE_STYLES_IN_SELECTION_RESIZE', {
          width: this.selectedCrop.sizeBackground.width,
          height: this.selectedCrop.sizeBackground.height,
          transform: {
            translate: {
              x: this.selectedCrop.position.x,
              y: this.selectedCrop.position.y,
            }
          },
        })
        this.$store.dispatch('post/drag/RESIZE', {
          uuid: this.selected,
          width: this.selectedCrop.sizeBackground.width,//this.imageSelected.width,
          height: this.selectedCrop.sizeBackground.height, //this.imageSelected.height,
          x: this.selectedCrop.position.x,
          y: this.selectedCrop.position.y,
          rotate: 0,
          cropped: {
            width: this.selectedCrop.size.width,
            height: this.selectedCrop.size.height,
            x: this.selectedCrop.positionBackground.x,
            y: this.selectedCrop.positionBackground.y
          }
        })

      this.$store.dispatch("post/navControls/CROP_SAVED", true);
      this.$store.dispatch("post/navControls/CROP", false);
      this.$store.dispatch('post/image/UPDATE_IMAGE_SELECTED', this.selected);
      // this.$store.dispatch('post/SAVE_CHANGES');
      this.$store.dispatch("global/redoundo/SAVE_CHANGE_HISTORY");
},
    cancelCrop() {
      this.$store.dispatch("post/navControls/CROP", false);
    }
  },
};
</script>

<style scoped>
.v-menu__content {
  box-shadow: none;
}
.v-list {
  border-radius: 6px;
  padding: 5px;
  width: 180px; 
}

.v-list-item {
  cursor: pointer;
  min-height: 35px;
}

.v-list-item__content {
  padding: 0;
}
.v-list-item__paragraph {
  margin: 0;
  color: #5d6b88;
  font-family: Oxygen;
  margin-top: 3px;
}
</style>