<template>
  <v-slider 
    v-model="model" 
    :max="max" 
    :min="min" 
    hide-details
    :track-color="'#E9EAEE'"
    @end="$emit('end')"
  ></v-slider>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    max: {
        type: Number,
        default: 50
    },
    min: {
        type: Number,
        default: 0
    }
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-slider__thumb {
  cursor: pointer;
  border: 1px solid #fff !important;

  &::before {
    opacity: 0;
  }
}
::v-deep .v-slider__track-fill,
::v-deep .v-slider__track-background {
  border-radius: 9px;
}
::v-deep .v-slider__track-container {
    height: 5px;
  }

::v-deep .v-slider--horizontal {
  margin: 0;
}
</style>